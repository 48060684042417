.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  justify-content: flex-start;
}
.billings-grid-container {
  padding-top: 10px;
  &.mtp {
    padding: 10px;
  }
  &.mtp2 {
    padding: 20px;
  }
}

.ag-theme-balham {
  .ag-cell-wrapper {
    display: flex;
    justify-content: center;
  }
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
}

.billings-container-grid {
  .ag-body-viewport {
    animation: cssAnimation 0s 1s forwards;
    visibility: hidden;
  }
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.icon-grid {
  span {
    i {
      padding-right: 8px;
      cursor: pointer;
    }
  }
}
