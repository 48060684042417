.specialistSearch{
	.filtersTitle{
		// width:100px;
		margin-right:30px;
		color:#676062;
		font-size:25px;
		font-weight: bold;
		display:flex;
		flex-direction:row;
		align-items:flex-end;
		.filtersCount{
			width:60px;
			font-size:13px;
			margin-left:10px;
			color:$greyNew;
		}
	}
	.filtersButtonContent{
		button{
			background:$blueNew;
			color:$whiteNew;
			font-size:9px;
			text-transform: uppercase;
			letter-spacing: 1px;
			padding-top:0px;
			padding-bottom:0px;
			font-weight: bold;
			padding-left:18px;
			padding-right:18px;
			border-radius:15px;
			height:30px;
			border:1px solid $blueNew;
			line-height: 30px;
			&:hover{
				background:transparent;
				color:$blueNew;
			}
		}
	}
}
.filtersInspections{
	display:flex;
	flex-direction:row;
	align-items:center;
	height:60px;
	// background:red;
	position:fixed;
	top:45px;
	left:0px;
	right:0px;
	padding-left:20px;
	padding-right:20px;
	// div, ul {
	// 	flex:1;
	// }
	.filtersTitle{
		// width:100px;
		margin-right:30px;
		color:#676062;
		font-size:25px;
		font-weight: bold;
		display:flex;
		flex-direction:row;
		align-items:flex-end;
		.filtersCount{
			width:60px;
			font-size:13px;
			margin-left:10px;
			color:$greyNew;
		}
	}
	.filtersButtonContent{
		button{
			background:$blueNew;
			color:$whiteNew;
			font-size:9px;
			text-transform: uppercase;
			letter-spacing: 1px;
			padding-top:0px;
			padding-bottom:0px;
			font-weight: bold;
			padding-left:18px;
			padding-right:18px;
			border-radius:15px;
			height:30px;
			border:1px solid $blueNew;
			line-height: 30px;
			&:hover{
				background:transparent;
				color:$blueNew;
			}
		}
	}
	ul{
		margin-top:0px;
		margin-bottom:0px;
		margin-left:40px;
		margin-right:40px;
		padding:0px;
		// height:30px;
		// display:flex;
		// flex-direction:row;
		li{
			list-style-type: none;
			display: inline-block;
			background:$whiteNew;
			color:$grey2New;
			border-top:1px solid rgba(0,0,0,0.075);
			border-bottom:1px solid rgba(0,0,0,0.075);
			padding-top:0px;
			padding-bottom:0px;
			height:30px;
			line-height: 30px;
			padding-left:30px;
			padding-right:30px;
			font-size:12px;
			cursor:pointer;
			i{
				margin-right:5px;
			}
			&:first-child{
				border-top-left-radius:15px;
				border-bottom-left-radius:15px;
			}
			&:last-child{
				border-top-right-radius:15px;
				border-bottom-right-radius:15px;
			}
			&:hover, &.selected{
				border-color:$blueNew;
				background:$blueNew;
				color:$whiteNew;
			}
		}
	}
}