.expandable-hit {
  width: 100%;
  // min-height: 80px;
  background: transparent;
  border-top: solid 1px #e0e0e0;
  &.open {
    min-height: 100px;
  }
}
.ais-Pagination-item--selected .ais-Pagination-link {
  background: #1c518a;
  border-color: transparent;
}
.ais-Pagination-link {
  color: #1c518a;
  border-color: #18508c33;
}
.ais-Pagination-list {
  list-style: none;
  display: flex;
  align-content: center;
  justify-content: center;
}
.ais-Hits-list {
  list-style-type: none;
  padding: 0px;
  min-width: 900px;
}
.ais-SearchBox-form {
  box-shadow: 0 2px 4px rgba(5, 15, 44, 0.1);
  border: none;
  padding: 10px;
  background: white;
  border-radius: 8px;
}
.ais-SearchBox-input {
  border: none;
}
.ais-Hits-item {
  background: white;
  border: 0;
  &:nth-child(2n) {
    background: #f5f5fa;
  }
  padding: 0px;
  margin-top: 0px;
  width: 100%;
  &:first-child {
    margin-top: 10px;
    &:hover {
      background: white;
      cursor: default;
    }
  }
  &:hover {
    cursor: pointer;
    background: #e4e4e8;
  }
  div {
    // &:first-child {
    margin-top: 0px;
    // }
  }
}
.hit-container {
  &.labels {
    color: #999;
  }
  padding: 10px;
  /* Temporary ? */
  display: flex;
  align-items: center;
  justify-content: space-around;
  // div {
  .hit-name {
    &:before {
      background: red;
    }
    width: -webkit-fill-available;
    text-align: center;
    overflow: hidden;
  }
  // }
}
.ais-Pagination {
  margin-top: 10px;
}
.search-container {
  .filters {
    margin: 5px;
    .filter {
      display: inline-block;
      background: white;
      margin: 5px;
      border-radius: 10px;
      padding: 5px 10px 5px 10px;
      box-shadow: 0 2px 4px rgba(5, 15, 44, 0.1);
      &:hover {
        background: #e8eaeffa;
        cursor: pointer;
      }
      &.active {
        background: #1c518a;
        color: white;
      }
    }
  }
}

.logs-admin-container {
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 900px;
}

.badge-statut {
  color: white;
  border-radius: 15px;
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  &.active {
    background: #19b5fe;
  }
  &.blocked {
    background: #ef4836;
  }
  &.confirmed {
    background: #446cb3;
  }
  &.unconfirmed {
    background: #f2784b;
  }
  &.new {
    background: #bdc3c7;
  }
}

.ais-SearchBox,
.ais-Stats {
  padding-left: 10px;
  padding-right: 10px;
}
