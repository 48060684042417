.ant-drawer-content-wrapper {
  width: 400px !important;
}

.ant-drawer-header {
  background-color: #dee1e7;
  border-color: white;
  border-radius: 0;
  padding: 16px;
}

.ant-drawer-body {
  background-color: #dee1e7;
  padding: 10px;
}

.ant-drawer-title,
.ant-drawer-close {
  color: $blueDark;

  .badge {
    margin-left: 2rem;
  }
}

.ant-drawer-close {
  right: -10px;
  top: -10px;
  font-size: 13px;
}

details {
  --shadow: hsla(0, 0%, 0%, 0.05);
  --shadow-light: hsla(0, 0%, 95%, 0.1);
  --shadow-dark: hsla(0, 0%, 0%, 0.075);
  border-radius: 5px;
  box-sizing: border-box;
  transition: box-shadow 500ms cubic-bezier(0.22, 1, 0.36, 1),
    transform 225ms cubic-bezier(0.45, 0, 0.55, 1);
  width: 100%;
  background-color: $blueDark;
  color: white;

  @media (min-width: 560px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }

  &:hover {
    box-shadow: -6px -6px 12px var(--shadow-light, transparent),
      6px 6px 12px var(--shadow, transparent);
  }

  &:active {
    box-shadow: -4px -4px 8px var(--shadow-light, transparent),
      4px 4px 8px var(--shadow, transparent);
  }

  &:active {
    transform: scale(.995);
  }

  // Open state
  &[open] {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  // Subsequent details
  +details {
    margin-top: 12px;
  }

  >summary {
    cursor: pointer;
    font-weight: 400;
    outline: none;
    padding: .5rem .95rem;
  }

  .body {
    background-color: white;
    border-radius: 0 0 5px 5px;
    padding: .75rem 1rem .75rem;
    color: $blueDark;

    >p,
    >ul {
      font-size: .875rem;
      line-height: 1.6;
      margin-top: 0;
    }

    >p {
      margin-bottom: 0;
      padding: 0 0 .5rem;
    }
  }
}

// You can update the triangle to be whatever you would
// like by using the following code:
details {
  summary {
    position: relative;

    .buttonUpdate {
      position: absolute;
      right: 5px;
      font-size: .9rem;
      top: 7px;
      border-radius: 10px;
      color: black;
      background-color: #fff;
      box-shadow: none;
      border: none;
      text-transform: lowercase;
    }

    &::-webkit-details-marker {
      // display: none;
    }
  }
}

.ant-drawer-content {
  overflow: hidden;
}
