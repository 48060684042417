$colorBlueLight: #4A90E2;

.billings-container {
  .filtersLogs {
    justify-content: center;
  }
}

.modalBilling {
  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    height: auto;
    margin-bottom: 20px;
    padding: 10px 0 10px 5px;
    position: relative;
    font-size: 0.9rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.025);
    text-align: left;
    transition: height 1000ms;
    width: 100%;

    .colorGray {
      color: $greyNew;
    }

    .colorBlue {
      color: $blueDark;
    }

    .label {
      margin-top: 30px;
      transform: translateY(10px);
      transition: transform 1000ms;
      color: $greyNew;
    }

    .bloc-left {
      padding-left: 3%;
      border-right: 1px solid lightslategray;

      &.pr-4 {
        padding-right: 4%;
      }

      .blocModel {
        border-top: 1px solid lightslategray;
        margin-top: 15px;

        .idInspection {
          font-style: italic;
          font-size: 1rem;
          color: $colorBlueLight;
        }
      }

      .inspectionTitle {
        .nbrInspectionColor {
          color: $colorBlueLight;
        }
      }
    }

    .bloc-right {
      padding-left: 3%;
      padding-right: 4%;
    }

    .subContent {
      clip-path: inset(100px 50px);
      -webkit-clip-path: inset(100px 50px);
      transition: clip-path 1000ms;
      display: table-column;

      .ant-input-number {
        border-radius: 0;
        width: 20%;

        .ant-input-number-input {
          height: 15px;
          border-radius: 0;
          font-size: 11px;
        }
      }

      .text-content {
        transform: translateY(-160px);
        transition: transform 1000ms;
      }
    }

    .chevron {
      position: absolute;
      top: 15px;
      left: 5px;
      transform-origin: 50%;
      transform: rotate(90deg);
      transition: transform 1000ms;
    }

    .commentTextArea {
      height: 70px;
      margin-top: 5px;
      padding: 5px;
      border-radius: 4px;
      font-size: 12px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      width: 100%;
      margin-bottom: 5px;
    }

    .pt-15 {
      padding-top: 15px;
    }

    .flRight {
      float: right;
      color: $blueDark;
    }

    .bolder {
      font-weight: bold;
    }

    &.expanded {
      height: auto;
      padding-bottom: 1.5rem;

      .css-kj6f9i-menu {
        display: block;
        overflow-y: auto;
        position: relative;
      }

      .label {
        transform: translateY(0);
      }

      .subContent {
        clip-path: inset(-100px -50px);
        -webkit-clip-path: inset(-100px -50px);
        display: block;
      }

      .text-content {
        transform: translateY(0);
      }

      .chevron {
        transform: rotate(0deg);
      }
    }

    .selectComment {
      width: 86%;
      display: inline-block;
      margin-right: .5rem;
      margin-bottom: 1rem;
    }

    .addSelectComment {
      display: inline-block;
      font-size: 2rem;
      color: $blueDark;
      text-align: center;
    }

    .styles-module_Editext__view_container__3oSYx {
      justify-content: space-between;
    }

    .editButton-true {
      display: none;
    }
  }
}

.modalChangeStatusBilling {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .card {
    left: calc(50% - 20px);
    transform: translateX(-50%);
  }
}
