#mapContainer {
	height: 100%;
	width: 100%;
	position: relative;

	#map {
		height: 100%;
		width: 100%;
	}

	.filterZoneRight {
		position: fixed;
		top: 140px;
		right: 10px;
		width: 280px;
		z-index: 10;
	}

	.filterZone {
		position: fixed;
		top: 140px;
		left: 10px;
		width: 280px;
		z-index: 10;
	}

	button {
		&.simple {
			color: rgba(0, 0, 0, 0.8);
			padding-left: 0px;
			margin-top: 5px;
		}
	}

	.greyIcon {
		color: rgba(0, 0, 0, 0.3);
	}

	.filterZone,
	.filterZoneRight {
		.filterZoneContainer {
			background: white;
			padding: 20px;
			border-radius: 4px;
			margin-bottom: 10px;
			@extend .boxshadow;

			.formInput {
				margin-top: 10px;
				margin-bottom: 0px;
				width: 100% !important;
			}

			.addressContainer {
				font-size: 12px;
				margin-top: 4px;
			}

			button.simple {
				font-size: 11px;
				margin-left: 5px;
				border: none;
				text-decoration: underline;
			}

			.filterZoneContainerTitle {
				font-size: 9px;
				text-transform: uppercase;
				letter-spacing: 1.2px;
				color: #676062;
				padding-left: 4px;
				margin-bottom: 3px;
				display: block;
				display: flex;
				flex-direction: row;
				align-items: center;

				div {
					flex: 1;
				}

				button {
					// float:right;
					border: none;
					color: #676062;
					font-size: 11px;
					padding-top: 0px;
					padding-bottom: 0px;

					&:hover {
						color: $blueNew;
					}
				}
			}

			.filterZoneContent {
				button {
					font-size: 11px;
					margin-right: 5px;
					margin-bottom: 5px;
					border-radius: 4px;

					i {
						margin: 0px !important;
						padding: 0px !important
					}

					&.actifs {
						color: #19B5FE;
						border: 1px solid #19B5FE;

						&:hover,
						&.active {
							background: #19B5FE;
							color: white;
						}
					}

					&.confirmes {
						color: #446CB3;
						border: 1px solid #446CB3;

						&:hover,
						&.active {
							background: #446CB3;
							color: white;
						}
					}

					&.nonconfirmes {
						color: #F2784B;
						border: 1px solid #F2784B;

						&:hover,
						&.active {
							background: #F2784B;
							color: white;
						}
					}

					&.refuses {
						color: blue;
						border: 1px solid blue;

						&:hover,
						&.active {
							background: blue;
							color: white;
						}
					}

					&.inactifs {
						color: #BDC3C7;
						border: 1px solid #BDC3C7;

						&:hover,
						&.active {
							background: #BDC3C7;
							color: white;
						}
					}

					&.all {
						color: $blueNew;
						border: 1px solid $blueNew;

						&:hover,
						&.active {
							background: $blueNew;
							color: white;
						}
					}
				}
			}

			ul.legend {
				margin: 0px;
				padding: 0px;

				li {
					list-style-type: none;
					font-size: 12px;
					padding-top: 4px;
					padding-bottom: 4px;

					div {
						display: inline-block;
						width: 10px;
						height: 10px;
						margin-right: 5px;

						&.actifs {
							background: #19B5FE;
						}

						&.confirmes {
							background: #446CB3;
						}

						&.nonconfirmes {
							background: #F2784B;
						}

						&.refuses {
							background: blue;
						}

						&.inactifs {
							background: #BDC3C7;
						}
					}
				}
			}
		}
	}

	.filterZoneRight {
		ul {
			padding: 0px;
			margin: 0px;
			max-height: 400px;
			overflow-y: scroll;

			li {
				padding-left: 6px;
				padding-right: 6px;
				padding-top: 5px;
				padding-bottom: 5px;
				font-size: 12px;
				list-style-type: none;
				color: rgba(0, 0, 0, 0.8);
				border-bottom: 1px solid rgba(0, 0, 0, 0.15);
				cursor: pointer;

				div {
					margin-bottom: 4px;

					&:last-child {
						margin-bottom: 0px;
					}
				}

				i {
					color: rgba(0, 0, 0, 0.3);
				}

				&:hover {

					// background: rgba(0,0,0,0.1);
					div:first-child {
						color: $blueNew;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

.marker {
	width: 35px;

	&:hover {
		z-index: 1000;
		transform: translate(0, -10%);
	}
}


.cluster-marker {
  color: #fff;
	width: 40px;
  background: #f7001569;
  border-radius: 50%;
  padding: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
	&:hover {
		z-index: 1000;
		transform: translate(0, -10%);
	}
}

.marker-container {
	position: relative;
  text-align: center;
	width: 50px;
	right: 25px;
	bottom: 35px;
}
.inspections-quantity {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: 900;
}

.inspections-busy {
	position: absolute;
	top: 7%;
	left: 65%;
	transform: translate(-50%, -50%);
	width: 15px;
}


.inspection-marker {
  background: none;
  border: none;
}

.inspection-marker img {
  width: 5px;
}

.specialistDetails {
	width: 350px;
	z-index: 100;

	.infos {
		font-size: 12px;
		padding-bottom: 8px;
		color: rgba(0, 0, 0, 0.8);

		i {
			font-size: 13px;
			color: rgba(0, 0, 0, 0.3);
		}
	}
}

.elementContent {
	margin: 6px 0px 18px 20px;
	min-width: 280px;
	overflow-x: hidden;
	padding: 10px 0px;
	position: relative;

	ul {
		margin: 0px;
		padding: 0px;

		li {
			list-style-type: none;
			font-size: 12px;
			padding-bottom: 5px;

			&.mainTitle {
				font-size: 14px;
				font-weight: bold;

				.badge {
					margin-left: 10px;
				}
			}

			&.leftText {
				text-align: left;

				i {
					margin-right: 10px;
				}
			}

			&.mainName {
				font-size: 16px;
			}

			.underline {
				text-decoration: underline;
			}

			a {
				color: $blueNew;
			}

			i {
				color: #BDC3C7;

				&.red {
					color: red !important;
				}
			}
		}
	}

	.commentDiv {
		padding: 10px 8px;
		margin-top: 10px;
		border-radius: 4px;
		font-size: 12px;
		background: #f1f1f1;
		color: #777777;
		max-width: 400px;
	}

	.googlePlacesAutoComplete {
		width: auto !important;
	}

	.formInput {
		margin-bottom: 6px;
	}

	.mainInfosContent {
		margin-top: 10px;
		padding: 6px 10px;
		background: #f1f1f1;
		font-size: 12px;

		.title {
			font-weight: bold;
			color: #777777;
		}

		.content {
			color: rgb(0, 0, 0);

			input[type='file'] {
				display: none;
			}

			ul {
				padding: 0px;
				margin: 0px;

				li {
					list-style-type: none;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding-bottom: 0px;

					.main {
						flex: 1;
					}

					.action {
						background: transparent;
						border: none;
						font-size: 12px;
						color: $blueNew;
					}

					.simple {
						background: transparent;
						border: none;
						margin-top: 0px !important;

						a {
							font-size: 12px;
							color: black;

							&:hover {
								color: $blueNew;
							}
						}
					}
				}
			}
		}
	}

	.badgesContainer {
		margin-top: 10px;

		.badge {
			margin-top: 2px !important;
			margin-right: 8px;

			&:first-child {}
		}
	}
}

.searchBox {
	position: absolute;
	top: 1rem;
	right: 8px;
	z-index: 2;
	box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;

	input {
		width: 300px;
		height: 35px;
		font-size: 1rem;
		padding-left: 4%;
		padding-right: 8%;
		border: 1px solid lightgrey;
		outline: 0;
	}

	.searchBox:focus {
		box-shadow: 0 0 15px 5px #b0e0ee;
		border: 2px solid #bebede;
	}

	.close-icon {
		border: 1px solid transparent;
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		outline: 0;
		cursor: pointer;
	}

	.close-icon:after {
		content: "x";
		display: block;
		width: 15px;
		height: 15px;
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 0;
		bottom: 0;
		margin: auto;
		padding-bottom: 25px;
		border-radius: 50%;
		text-align: center;
		color: #1c1c1c;
		font-weight: normal;
		font-size: 14px;
		cursor: pointer;
	}

	.close-icon:hover {
		color: $blueNew;
	}

	input:not(:valid)~.close-icon {
		display: none;
	}
}