$break-xxsmall: 580px;
$break-xmedium: 800px;

.filtersLogs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fcfcfc;
  border-radius: 15px;
  margin-bottom: 10px;
  width: 100%;
  &.concessionFilter {
    position: relative;
    margin-left: 30px;
    margin-bottom: 0 !important;
    justify-content: center;
    background: transparent;
    top: 0;

    ul {
      background: transparent;

      li {
        margin-bottom: .5rem;
        margin-right: 1rem;
      }
    }
  }
  &.frevo {
    height: 30px;
    padding: 38px 0 38px 0;
    .filtersList {
      display: flex;
    }
    .font-title-blue {
      @media screen and (max-width: $break-xmedium) {
        font-size: 12px;
      }
      @media screen and (max-width: $break-xxsmall) {
        display: none;
      }
    }
    .fraivo-list-concessions {
      position: relative;
      border-radius: 10px;
      border: 1px solid #d5d5d5;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: 42%;
        right: .75em;
        display: block;
        width: 0; height: 0;
        border-color: transparent;
        border-top-color: #444;
        border-width: 6px;
        border-style: solid;
        pointer-events: none;
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: auto;
        border: 0;
        margin: 0;
        padding: .75em 1.90em .75em .75em;
        border-radius: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .filtersTitle {
    margin-right: 30px;
    color: #676062;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .filtersCount {
      width: 60px;
      font-size: 13px;
      margin-left: 10px;
      color: $greyNew;
    }
  }
  .apikeyContent {
    padding-top: 10px;
    width: 330px;
    .apikeyValue {
      color: $blueNew;
      span {
        font-weight: bold;
        color: $greyNew;
      }
      font-size: 13px;
      margin-bottom: 4px;
    }
    .apikeyBtn {
      button {
        background: transparent;
        border: none;
        font-size: 12px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        margin-right: 25px;
        width: 55px;
      }
    }
  }
  .filtersButtonContent {
    button {
      background: $blueNew;
      color: $whiteNew;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      padding: 0 18px;
      border-radius: 15px;
      height: 30px;
      border: 1px solid $blueNew;
      line-height: 30px;
      &:hover {
        background: transparent;
        color: $blueNew;
      }
    }
  }
  ul {
    margin: 0 40px;
    padding: 0;
    border-radius: 15px;
    background: white;
    li {
      list-style-type: none;
      display: inline-block;
      background: $whiteNew;
      color: $grey2New;
      height: 30px;
      line-height: 30px;
      padding: 0 30px;
      font-size: 1.1rem;
      border-radius: 15px;
      cursor: pointer;
      margin: 0 .2rem;
      i {
        margin-right: 5px;
      }
      &:hover,
      &.selected {
        border-color: $blueNew;
        background: $blueNew;
        color: $whiteNew;
      }
    }
  }
}
.ant-checkbox + span {
  width: 100%;
}
