$firstColor: #0a4865;

.specialists-container {
  height: 80vh;
  position: relative;
}

.specialistsContainerPart {
  height: calc(100% - 28px);
  // overflow-y: hidden;
  position: relative;
}
.appPaginator {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  background: white;
  line-height: 30px;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  button {
    text-align: center;
    min-height: 30px;
    min-width: 30px;
    font-weight: normal;
    font-size: 11px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    &.current {
      background: $firstColor;
      border-color: $firstColor;
      color: white;
    }
    &:hover {
      background: $firstColor;
      color: white;
    }
  }
}
.specialistsContainer {
  height: calc(100% - 80px);
  overflow-y: scroll;
  .noSpecialistFound {
    padding: 15px;
  }
}
.specialistsContainerHeader {
  display: table;
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 32px;
  margin-bottom: 10px;
  > div {
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .specialistsContainerHeaderItem {
    display: table-cell;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    color: $firstColor;
    letter-spacing: 1px;
    height: 32px;
    line-height: 32px;
    &.specialistsContainerHeaderId {
      width: 70px;
    }
    &.specialistsContainerHeaderDate {
      width: 100px;
      // background: blue;
    }
    &.specialistsContainerHeaderEmail {
      width: 250px;
    }
    &.specialistsContainerHeaderStatus {
      width: 100px;
      // background: red;
    }
    &.specialistsContainerHeaderExperienceYear {
      width: 60px;
    }
    &.specialistsContainerHeaderCity {
      width: 140px;
    }
    &.specialistsContainerHeaderPhone {
      width: 100px;
    }
  }
}

.specialistItem {
  display: table;
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
  padding: 3px;
  min-height: 30px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  &:hover {
    background: #bdc3c7;
  }
  .specialistItemElement {
    display: table-cell;
    font-size: 12px;
  }
  .specialistItemId {
    width: 70px;
  }
  .specialistItemDate {
    width: 100px;
  }
  .specialistItemEmail {
    width: 250px;
    text-align: center;
  }
  .specialistItemStatut {
    width: 100px;
  }
  .specialistItemExperienceYear {
    width: 60px;
    text-align: center;
  }
  .specialistItemCity {
    width: 140px;
  }
  .specialistItemPhone {
    width: 100px;
    text-align: center;
  }
  .specialistItemComment {
    padding-left: 10px;
  }
}

.statsTable {
  width: 100%;
  tr {
    &:nth-child(2n + 1) {
      background: #6c7a89;
      color: white;
    }
    td {
      padding: 5px;
      border-collapse: collapse;
      font-size: 12px;
    }
  }
}
