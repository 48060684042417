@keyframes spinneriminen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  min-height: 60em;

  .c-car-spinner {
    height: 120px;
    margin-top: 20px;
    width: 120px;
    position:relative;
  }

  .c-car-spinner__svg {
    animation: spinneriminen 1s linear infinite;
    position: absolute;
    top: 0;
  }

  .c-car-spinner__svg-circle {
    stroke: rgba(0,0,0,.3);
    stroke-dasharray: 140;
    transform-origin: center center;
    fill: transparent;
    stroke-width: 2px;
  }

  .c-car-spinner__svg-car{
    position: absolute;
    top: calc(50% - 20px);
    left:calc(50% - 25px);
  }
}

