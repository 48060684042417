/* Search bar */

.TreeViewDemo>input[type="search"] {
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  height: 3.6em;
  border-width: 1.5px;
  transition: border 0.5s;
  background: white;
  border-radius: 6px;
  border-style: solid;
  border-color: #cecece;
  margin-left: 4px;
  margin-right: 8px;
  padding: 15px;
}

/* Elements */

.TreeViewDemo {
  //box-shadow: 0px 0px 10px #DADADA;
  white-space: nowrap;
  margin: 15px;
}

.TreeViewDemo ul {
  list-style: none;
}

.TreeViewDemo li {
  min-width: 100px;
  transition: all 0.25s ease-in-out;
}

.TreeViewDemo ul li a {
  color: #1f5288;
  //font-family: 'Avenir Next LT Pro Regular', serif;
  font-weight: 500;
  font-size: 14px;
}

.TreeViewDemo ul li>.item>a {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 55px);
  margin-right: 30px;
  padding: 10px 5px;
  text-decoration: none;
  transition: all 0.25s;
}

.TreeViewDemo ul li:not(.disabled) {
  cursor: pointer;
}

.TreeViewDemo ul li.selected>.item>a {
  color: #f2784b;
}

.TreeViewDemo ul li.selected>.item>a:hover {
  color: #aaa;
}

.TreeViewDemo ul li:not(.disabled)>.item>a:hover {
  //color: #e26f6f;
  color: #f2784b;
}


/* Root elements */

.TreeViewDemo ul.depth-0 {
  padding: 20px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.4);
  user-select: none;
  transition: all 0.25s;
}


/* Categories : Nodes with children */

.TreeViewDemo li.category>.item {
  display: block;
  margin-bottom: 5px;
  transition: all 0.25s ease-in-out;
}

.TreeViewDemo li.category:not(.folded)>.item {
  border-bottom: 1px solid #1f5288;
}


/* Category opener */

.TreeViewDemo .opener {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  cursor: pointer;
}

.TreeViewDemo .opener::after {
  content: '+';
  display: block;
  transition: all 0.25s;
  font-family: monospace;
}

.TreeViewDemo li.category.async>.item>.opener::after {
  content: '!';
}

.TreeViewDemo .opener:hover {
  color: #e26f6f;
}

.TreeViewDemo li.category:not(.folded)>.item>.opener::after {
  //color: #f2784b;
  color: #1f5288;
  transform: rotate(45deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

.TreeViewDemo li.category.loading>.item>.opener::after {
  animation: spin 1s infinite;
}


/* Animations on fold / unfold */

.TreeViewDemoTransition-enter {
  opacity: 0;
  transform: translateX(-20px) scale(0);
  transform-origin: top;
  transition: all .3s ease-in-out;
}

.TreeViewDemoTransition-enter-active {
  opacity: 1;
  transform: translateX(0px) scale(1);
}

.TreeViewDemoTransition-leave {
  transition: all .3s ease-in-out;
  transform-origin: top;
}

.TreeViewDemoTransition-leave-active {
  opacity: 0;
  transform: translateX(-20px) scale(0);
}


/* Drag'n'drop */

.TreeViewDemo li.dragover, .TreeViewDemo ul.dragover {
  box-shadow: 0px 0px 5px #CCC
}

.TreeViewDemo ul.dragover {
  background-color: rgba(200, 200, 200, 0.3);
}

.TreeViewDemo li.dragover {
  background-color: rgba(100, 100, 100, 0.05);
  padding: 0px 5px;
}

.TreeViewDemo li.dragover>span.item {
  border-color: transparent;
}

.TreeViewDemo li.nodrop {
  box-shadow: 0px 0px 5px #f2784b;
  background-color: rgba(255, 20, 60, 0.1);
  padding: 0px 5px;
}
