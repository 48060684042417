.googlePlacesAutoComplete{
	position:relative;
	z-index:1;
	max-width:300px;
	label{
		line-height: 10px!important;
	}
	ul{
		position:absolute;
		top:50px;
		left:0px;
		right:0px;
		margin:0px;
		padding:0px;
		z-index:1;
		margin-bottom:0px;
		background:white;
		border:1px solid rgba(0,0,0,0.075);
		border-radius:4px;
		max-height:140px;
		overflow-y:scroll;
		li{
			list-style-type: none;
			padding:0px;
			padding-left:10px;
			padding-top:4px;
			padding-bottom:4px;
			font-size:12px;
			line-height:17px;
			border-radius: 0px!important;
			border-top-left-radius: 0px!important;
    		border-bottom-left-radius: 0px!important;
    		color: black !important;
    		width: 100%;
			&:hover{
				background: $blueLight;
			}
		}
	}
}