.chart {
  padding: 10px;
  // -webkit-transition: width 2s, height 4s; /* For Safari 3.1 to 6.0 */
  // transition: width 2s, height 4s;
  transition-property: all;
  transition-duration: .5s; /* on aurait pu écrire 500ms également */
  transition-timing-function: ease-in;
  transition-delay: .5s;
  .expand-button {
    cursor: pointer;
    &:hover {
      color: grey;
    }
  }
  .container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 5px;
  }
}
.concession-container {
  &.user {
    padding: 0px;
    .table {
      .table-search {
        background: #dedede;
      }
    }
  }
  // overflow-x: hidden;
  padding: 20px;
  &.mt {
    margin-top: 80px;
  }
  .row {
    margin: 0px;
  }
  .chart {
    .label {
      border-bottom: solid 1px #EBEBEB;
      padding-left: 5px;
    }
  }
  .header-stats {
    padding: 10px;
    button {
      &.search {
        margin-right: 10px;
        height: 35px;
        border-radius: 25px;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.15);
        background-color: $blueNew;
        border: solid 1px $blueNew;
        color: white;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 15px;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background: #385472;
          border: solid 1px #385472;
        }
        @media screen and (max-width: $break-medium) {
          padding-right: 0px;
          span {
            display: none;
          }
        }
      }
    }
    &.select {
      width: 100%;
      max-width: 250px;
    }
    .date-container-input {
      display: flex;
      align-items: center;
      .date-input {
        padding: 11px;
        width: 170px;
      }
      i {
        color: #1f5288;
        margin: 5px;
        font-size: 30px;
      }
    }
    .daterange {
      position: absolute;
      background: white;
      z-index: 1;
      border: solid 1px #d5d5d5;
    }
  }
}
.concession-header {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 1rem;
  width: 100%;

  &.concessionsMissions {
    margin-bottom: 1rem;
  }

  .action-bar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: end;
    background-color: white;
    border-radius: 15px;
    .divider {
      width: 1px;
      height: 40px;
      opacity: 0.15;
      background-color: #150000;
    }
    .item {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:hover {
        cursor: pointer;
        p {
          text-decoration: underline;
        }
      }
      i {
        font-size: 20px;
        margin-right: 7px;
        margin-left: 7px;
        margin-bottom: 7px;
      }
    }
  }
}

.databox {
  max-width: 300px;
  width: 100%;
  max-height: 127px;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 10px;
  .value {
    display: inherit;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  // &:hover {
    // box-shadow: 1px 8px 20px grey;
    // -webkit-transition: 'box-shadow' .6s ease-in;

    // &:after {
    //   opacity: 1;
    // }
  // }

  // position: relative;
  // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
