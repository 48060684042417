.customElementSelector{
	position:relative;
	label{
		line-height: 10px;
	}
	.itemsList{
		position:absolute;
		top: 50px;
		left: 0px;
		z-index:10;
		width: 230px;
		max-height: 90px;
		border-radius:3px;
		overflow-y: scroll;
		background:white;
		@extend .boxshadow;
		.item{
			font-size:12px;
			padding-left:5px;
			padding-right:5px;
			padding-top:5px;
			padding-bottom:5px;
			cursor: pointer;
			border-left: 4px solid white;
			border-bottom: 1px solid rgba(0,0,0,0.075);
			div{
				line-height: 15px;
			}
			.email{
				color:$greyNew;
			}
			&:hover{
				border-left-color:$blueNew;
			}
			&:last-child{
				border-bottom: none;
			}
		}
	}
}