html {
  font-size: 14px;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #dee1e7;
  font-size: 0.8em;
}

* {
  outline: none;
  font-family: 'Gilroy', sans-serif;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.lato {
  font-family: 'Gilroy', sans-serif;
}

.blue {
  color: $blueDark;
}

.appContainer {
  padding: 10px;
  width: 100%;

  h1 {
    color: $blueDark;
  }
}

.img50px {
  img {
    width: 50px;
    height: auto;
  }
}

.spacebtw {
  display: flex;
  justify-content: space-between;
}

.spacearound {
  display: flex;
  justify-content: space-around;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.mr5px {
  margin-right: 5px;
}

.mr10px {
  margin-right: 10px;
}

.mr20px {
  margin-right: 20px;
}

.mt10px {
  margin-top: 10px;
}

.mt15px {
  margin-top: 15px;
}

.mt20px {
  margin-top: 20px;
}

.mt25px {
  margin-top: 25px;
}

.mt30px {
  margin-top: 30px;
}

.mt120px {
  margin-top: 120px;
}

.mt150px {
  margin-top: 150px;
}

.ml10px {
  margin-left: 10px;
}

.ml15px {
  margin-left: 15px;
}

.ml20px {
  margin-left: 20px;
}

.ml25px {
  margin-left: 25px;
}

.ml30px {
  margin-left: 30px;
}

.mb10px {
  margin-bottom: 10px;
}

.mb15px {
  margin-bottom: 15px;
}

.mb20px {
  margin-bottom: 20px;
}

.mb25px {
  margin-bottom: 25px;
}

.mb30px {
  margin-bottom: 30px;
}

.m10px {
  margin: 10px;
}

.inline {
  display: inline-block;
}

.p10px {
  padding: 10px;
}

.p30px {
  padding: 30px;
}

.underline {
  text-decoration: underline;
}

.error {
  background: $red_error;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $red_error_color;

  p {
    color: $red_error_color;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.success {
  background: $green_success;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $green_success_color;

  p {
    color: $green_success_color;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.warning {
  background: $light_blue;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $blueNew;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.ordered-status {
  background: #F6AA1C;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: white;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.scheduled-status {
  background: #AEE063;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: white;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.ongoing-status {
  background: #F7D8C3;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: white;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.done-status {
  background: #8BB8D6;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: white;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.canceled-status {
  background: #d53e3e;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: white;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.dark {
  color: #fff;
  background-color: #6c757d;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.badge {
  padding: 3px 10px;
  font-size: 11px;
  border-radius: 4px;
  margin-bottom: 4px;
  // @extend .boxshadow;
}

.smalTitleCaps {
  font-size: 10px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: $greyNew;
}

p {
  &.content {
    margin-top: 4px;
    font-size: 13px;
    color: black;
  }

  &.simpleContent {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.titleFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

h1 {
  &.logo {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: normal;
    font-size: 16px;
    color: $greyNew;

    span {
      color: $blueNew;
    }
  }
}

.linkContainer {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;

  a {
    margin-bottom: 10px;
    font-size: 13px;
    color: $greyNew;

    &:hover {
      color: $blueNew;
    }
  }
}

.libelle {
  color: $greyNew;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.value {
  color: #434343;
  font-size: 12px;
}

.collapse {
  display: none;
  position: relative;
  transition: all .35s ease;
}

.collapse.show {
  display: block;
}

.generalTable {
  width: 100%;

  // height: 100%;
  // overflow-y:hidden;
  div {
    &.noDataFound {
      width: 100%;
    }

    &.row {
      width: 98% !important;
      margin-left: auto;
      margin-right: auto;
      background: white;
      margin-bottom: 8px;

      // display:table-row;
      .element {
        display: table-cell;
        padding: 10px 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
      }

      &.header {
        background: transparent;
        height: 40px;
        line-height: 50px;
        padding-top: 0px;
        padding-bottom: 0px;
        color: $greyNew;
        text-transform: uppercase;
        letter-spacing: 1.5px;

        .element {
          font-size: 10px;
        }
      }

      &.rowContent {
        // @extend .boxshadow;
        height: fit-content;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        width: 100%;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &:hover {
          background: rgba(93, 157, 244, 0.05);
          @extend .boxshadow;
        }
      }
    }

    &.menu {
      width: 20px;
      text-align: center;

      i {
        font-size: 16px;
      }

      position: relative;

      .btnContainer {
        display: none;
        position: absolute;
        top: 0px;
        right: 0px;
        // right: 22px;
        width: 160px;
        background: white;
        border-radius: 4px;
        @extend .boxshadow;
        text-align: left;
      }

      &:hover {
        .btnContainer {
          display: block;
          z-index: 10;

          .btnContainerItem {
            background: transparent;
            text-align: left;
            padding-top: 7px;
            padding-bottom: 7px;

            &.bg-white {
              button {
                background: white;
              }
            }

            a {
              padding-left: 8px;
              text-decoration: none;
            }

            a,
            button {
              font-size: 11px;
              width: 100%;
              text-align: left;
              color: rgba(0, 0, 0, 0.7);
              border: none !important;

              i {
                font-size: 13px;
                margin-right: 5px;
              }

              &:hover {
                color: $blueNew;
              }
            }
          }
        }
      }
    }

    &.body {
      height: calc(100% - 40px);
      overflow-y: scroll;
      align-content: baseline;
      width: 100% !important;
      background: transparent;
    }
  }
}

.radio-button {

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked+label,
  &:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  &:checked+label:before,
  &:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  &:checked+label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #20548c;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(:checked)+label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #20548c;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ant-select-tree-checkbox-inner {
  border-radius: 0;
}

.marker-container {
  img {
    max-width: 100%;
    width: 60%;
  }
}

.rag-green {
  background-color: #0a4865 !important;
  color: white !important;
}
