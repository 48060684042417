.horizontalForm {
  display: flex;
  flex-direction: row;

  .horizontalFormPart {
    flex: 1;
    margin: 10px;
  }
}

.centeredForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  .horizontalFormPart {
    flex: 1;

    .formInput {
      .email {
        display: flex;
        align-items: center;
        padding-left: 140px;

        .more-email {
          &:hover {
            background: transparent;
          }

          cursor: pointer;
          height: 29px;
          width: 30px;
          background: #75aaf2;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;

          i {
            color: #f6faff;
          }

          &:hover {
            background: #a7c8f5;
          }
        }
      }
    }
  }
}

.formTitle {
  font-size: 12px;
  color: $greyNew;
  // font-weight: bold;
  margin-bottom: 15px;
  text-decoration: underline;
}

.mainTitle {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 30px;
  letter-spacing: 0.6px;
  color: $greyNew;
  text-align: center;

  &.title {
    margin-bottom: 10px;
    color: black;
    text-align: left;
  }
}

form {
  a {
    color: $greyNew;
    text-decoration: underline;

    &:hover {
      color: $blueNew;
    }
  }
}

.formButton {
  text-align: center;

  button {
    padding: 0 15px;
    height: 25px;
    line-height: 22px;
    border-radius: 15px;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;

    &.positiv {
      background: $blueNew;
      color: $whiteNew;
      border: 1px solid $blueNew;

      &:hover {
        background: white;
        color: $blueNew;
        border-color: $blueNew;
      }
    }

    &.positiv--disabled {
      background: $grey2New;
      color: $whiteNew;
      border: 1px solid $grey2New;
    }

    &.orange {
      background: #f5a623;
      color: white;
      border: 1px solid #f5a623;

      &:hover {
        background: white;
        border: 1px solid black;
        color: black;
      }
    }

    &.negativ {
      background: transparent;
      color: $grey2New;
      border: 1px solid $grey2New;

      &:hover {
        background: $grey2New;
        color: $whiteNew;
      }
    }
  }
}

ul.stepForm {
  padding: 0;
  text-align: center;
  margin: 20px 0 30px;

  li {
    width: 150px;
    list-style-type: none;
    display: inline-block;
    text-align: center;

    div {
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      text-align: center;
    }

    .num {
      width: 35px;
      height: 35px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      line-height: 35px;
      font-size: 15px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid $greyNew;
      color: $greyNew;
      margin-bottom: 8px;

      i {
        margin-top: 10px;
      }
    }

    &:hover,
    &.active {
      color: $blueNew;

      .num {
        background: $blueNew;
        color: $whiteNew;
        border-color: $blueNew;
      }
    }
  }
}

.importSpreadsheet {
  text-align: center;
  border: 1px solid #c3c5c7;
  border-radius: 15px 15px 15px 15px;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  color: $grey2New;

  &:hover {
    border-color: #f5a623;
    color: #f5a623;
  }

  i {
    font-size: 60px;
    color: inherit;
  }
}
