.dateTimePicker{
    position:relative;
    .inputElement{
        background:white;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:4px;
        font-size:12px;
        height:26px;
        padding-top:0px;
        padding-bottom:0px;
        line-height:26px;
        padding-left:4px;
        color:black;
    }
    .inputSelectorContainer{
        position:absolute;
        top:32px;
        left:0px;
        z-index:9999;
        background:white;
        border-radius:4px;
        padding:10px;
        @extend .boxshadow;
    }
    .buttonElement{
      text-align: right;
      position: absolute;
      right: 3px;
      top: 0px;
      bottom: 0px;
      width: 20px;
      padding-top: 7px;
      padding-bottom: 0px;
      padding-right: 0px;
      background: transparent;
      cursor: pointer;
      color: rgba(0,0,0,0.8);
      border: none;
      font-size: 11px;
      width: 20px;
      i{
        font-size: 11px!important;
      }
      &:hover{
          color: $blueNew;
      }
    }
    .select-style {
        padding: 0px;
        margin: 0px;
        border: 1px solid #ccc;
        width: 120px;
        border-radius: 3px;
        overflow: hidden;
        background-color: #fff;
        background: #fff;
        position: relative;
        &:after{
            top: 50%;
            left: 85%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 0, 0, 0);
            border-top-color: #000000;
            border-width: 5px;
            margin-top: -2px;
            z-index: 100;
        }
        &.small{

        }
        select{
            padding: 5px 8px;
            width: 130%;
            border: none;
            box-shadow: none;
            background-color: transparent;
            background-image: none;
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            &:focus {
                outline: none;
            }
        }
    }

    .hourPicker{
        display:flex;
        flex-direction:row;
        align-items:center;
        >div{
            margin-right:10px;
        }
    }

    .calendar{
        width:154px;
        margin-left:auto;
        margin-right:auto;
        .calendarTitle{
            font-size:11px;
            margin-bottom:5px;
        }
        .selectContainer{
            display:flex;
            flex-direction:row;
            align-items:center;
            .select-style{
                width: 90px!important;
                margin-right:8px;
                select{
                    padding-top:2px;
                    padding-bottom:2px;
                    font-size:12px;
                    margin-right:10px;
                }
                &.year{
                    width:60px!important;
                    &:after {
                        left: 75%!important;
                    }
                }
                &.reallySmall{
                    width: 40px!important;
                    &:after {
                        left: 70%!important;
                    }
                }
            }
        }
        .calendarHeader{
            margin-top:10px;
            .calendarHeaderItem{
                display:table-cell;
                border:1px solid white;
                width: 20px;
                height:20px;
                font-size:11px;
                color:rgba(0,0,0,0.75);
                font-weight: bold;
                text-align: center;
            }
        }
        .calendarBody{
            .calendarRow{
                .calendarItem{
                    display:table-cell;
                    width: 20px;
                    height: 20px;
                    line-height:21px;
                    text-align: center;
                    border:1px solid white;
                    font-size:12px;
                    cursor:pointer;
                    color:rgba(0,0,0,0.65);
                    &:hover{
                        color:$blueNew;
                    }
                    &.active{
                        background:$blueNew;
                        color:white;
                    }
                }
                .datePassed{
                    background:rgba(0,0,0,0.10);
                }
                .calendarItemDisabled{
                    display:table-cell;
                    width: 20px;
                    height: 20px;
                    line-height:21px;
                    border:1px solid white;
                    background:rgba(0,0,0,0.10);
                }
            }
        }
        button.calendarValidate{
            width:152px;
            background:$blueNew;
            color:white;
            text-align:center;
            padding-top:0px;
            padding-bottom:0px;
            height:22px;
            font-size:12px;
            line-height: 20px;
            border:1px solid $blueNew;
            margin-top:5px;
            margin-bottom:10px;
            &:hover{
                background:white;
                color:$blueNew;
            }
        }
        &.big{
            width:259px;
            .calendarHeader{
                .calendarHeaderItem{
                    width: 35px;
                    height: 35px;
                }
            }
            .calendarBody{
                .calendarRow{
                    .calendarItem{
                        width: 35px;
                        height: 35px;
                        font-size:13px;
                        line-height:36px;
                    }
                    .calendarItemDisabled{
                        width: 35px;
                        height: 35px;
                        font-size:13px;
                        line-height:36px;
                    }
                }
            }
            button.calendarValidate{
                width:257px;
            }
        }
    }
}
// .formInput{
//     .inputElement, .buttonElement{
//         max-width: 300px;
//         box-sizing: border-box;
//     }
// }
