.inspections-grid-container {
  padding-top: 10px;
  &.mtp {
    padding: 10px;
  }
  &.mtp2 {
    padding: 20px;
    padding-top: 20px;
  }
}

.ag-theme-balham {
  .ag-cell-wrapper {
    display: flex;
    justify-content: center;
  }
}
