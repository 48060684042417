// .card {
//   background: #fff;
//   border-radius: 2px;
//   display: inline-block;
//   height: 300px;
//   margin: 1rem;
//   position: relative;
//   width: 300px;
// }

.pointer {
  cursor: pointer;
}
.shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.shadow-1:hover {
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.25), 0 0px 10px rgba(0, 0, 0, 0.22);
  
}