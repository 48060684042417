$greyBackground: #f7f8fa;
$greyDark: #6c7a89;
$blueDark: #054885;
$blueLight: #7bbefa;
$orange: #f29652;
// $blueNew: #5D9DF4;
$blueNew: #1f5288;
// $blueLight:#D4E5FC;
$greyNew: #676062;
$grey2New: #66686b;
$whiteNew: #eff1f3;
$red_error: #fadcd9;
$red_error_color: #e33c2c;
$green_success: #cbf3dc;
$green_success_color: #2ab665;
$light_blue: #dbedf9;

.green {
  color: $green_success_color;
}