.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  z-index: 1000;
}

.modal {
  flex-direction: column;
  max-width: 1000px;
  background: white;
  margin: 20px auto;
  padding: 30px;
  border-radius: 4px;
  position: relative;

  &.modalCheckpoint {
    width: 100%;
    max-width: 900px;

    input {
      max-width: 500px;
    }

    input[type="text"] {
      min-height: 38px;
      border-radius: 6px;
    }
  }

  &.modalREPORT {
    width: 100%;
    max-width: 1100px;

    input {
      max-width: 90%;
    }
  }

  &.modalScaleConfiguration {
    width: 100%;
    max-width: 720px;
    padding: 0;
    border-radius: 10px 10px 9px 9px;

    .mainTitle.header {
      background-color: #1f5288;
      padding: 15px 30px;
      border-radius: 9px 9px 0 0;
      margin-bottom: 0;

      span {
        color: white !important;
      }


      /* The Close Button */
      .close {
        color: #aaaaaa;
        font-size: 28px;
        font-weight: bold;
        position: absolute;
        right: 15px;
        top: 0;
      }

      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .modalContent {
      max-height: 80vh;
      padding: 30px 30px 0 30px;
      border-bottom: 1px solid lightgray;
      overflow: auto;

      &.changeStatus {
        overflow: visible;
      }

      .input-value {
        min-height: 38px;
        width: 100%;
      }

      .scrollIntoView {
        height: 26vh;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: #1f5288;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #1f5288;
        }
      }
    }

    .formButton {
      padding: 20px;
    }
  }

  &.modalConcession {
    width: 100%;
    max-width: 620px;

    input {
      max-width: 560px;
    }
  }

  &.modalImportSpreadsheet {
    width: 100%;
    max-width: 560px;
  }

  &.modalFRE {
    width: 100%;
    max-width: 800px;

    input {
      max-width: 800px;
      width: 80%;
      padding: 10px;
      border: 1px solid #366396a8;
      border-radius: 2px;
    }

    .mainTitle {
      margin-bottom: 0;
    }
  }

  &.modalInspectionBan,
  &.modalApikey {
    width: 300px;
  }

  &.modalEditUserInfos {
    width: 300px;
    padding: 20px;

    .mainTitle {
      margin-bottom: 10px;
    }

    .formInput {
      margin-bottom: 8px;
    }

    .formButton {
      margin-top: 10px;
    }
  }

  // position: absolute;
  //    top: 40px;
  //    left: 40px;
  //    right: 40px;
  //    bottom: 40px;
  // background:rgba(0,0,0,0.6);
  .modal-blue-bg {
    // background:rgba(123,190,250,0.2);
  }

  .modalContent {
    .listReportsButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      a {
        margin-left: 10px;
        margin-right: 10px;
        // &:first-child {
        //   margin-right: 0px;
        // }
      }
    }

    &.borderbottom {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    button.close {
      position: absolute;
      z-index: 2147483647;
      top: 10px;
      right: 8px;
      background: transparent;
      color: $greyDark;
      border: none;
      cursor: pointer;
      font-size: 15px;

      &:hover {
        color: $blueDark;
      }
    }

    .inspectionModalBan {
      img {
        width: 64px;
      }
    }
  }
}

.formModal {
  label {
    color: #9b9b9b;
  }

  .viewConcession {
    cursor: pointer;

    &:hover {
      color: $blueDark;
    }
  }
}

.SortableList {
  position: relative;
  z-index: 0; //9999999
  background-color: #f3f3f3;
  border-radius: 3px;
  outline: none;

  width: 99%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 999999999;

  &.infinite {
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 7px;
  }
}

.SortableItemHorizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 35px;
  padding: 0 12px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 999999999;
  float: left;
  margin: 0 1px;

  &.infinite {
    justify-content: space-between;
  }

  &.selected {
    background-color: #1f5288;
    color: #fff;
  }
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  z-index: 9999999999;
}

.InfiniteList {
  position: relative;
  z-index: 0; //9999999
  background-color: #f3f3f3;
  border-radius: 3px;
  outline: none;

  width: 85%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.InfiniteList::-webkit-scrollbar {
  -webkit-appearance: none;
}

.InfiniteList::-webkit-scrollbar:vertical {
  width: 11px;
}

.InfiniteList::-webkit-scrollbar:horizontal {
  height: 11px;
}

.InfiniteList::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.btnIcon {
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 6px 6px 6px 11px;
  /* Some padding */
  font-size: 12px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  margin-left: 6px;
  border-radius: 30px;

  &.delete {
    background-color: #f2784b;
  }

  &.add {
    background-color: #1f5288;
  }
}

.ant-switch {
  min-width: 20%;
  height: 15px;
  line-height: 15px;
  margin-bottom: 6px;
}

.ant-switch-handle {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}

.btnIcon:hover {
  &.delete {
    background-color: #ff500f;
  }

  &.add {
    background-color: #599adf;
  }
}

.multi-select__control {
  height: 20px;
}

.multi-select__input {
  height: 22px;
}

.modalFont {
  font-size: 1.1em;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  letter-spacing: 0.2px;
}

.modalFontBig {
  font-size: 16px;
  font-weight: 700;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  letter-spacing: 1px;
}

.typeIcons {
  align-self: center;
  margin-right: 10px;
  color: #ddd;
  font-size: 15px;
}

.modalScaleConfiguration {
  .error {
    text-align: center;
  }

  .ant-input-number-input {
    height: 36px;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: 0;
  }

  .modalContent {
    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
      padding-left: 25px;
      font-family: "Avenir Next LT Pro Bold", "Avenir Next LT Pro Regular", sans-serif;
      color: black;
    }
  }

  .site-collapse-custom-panel:first-child {
    margin-bottom: 0;
  }

  .modalFontBig {
    font-family: "Avenir Next LT Pro Bold", "Avenir Next LT Pro Regular", sans-serif;
    color: black;
  }

  .dataNotFound {
    margin: 0 0 2rem;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
  }

  .input-euro {
    position: relative;
  }

  .input-euro.left input {
    padding-left: 18px;
  }

  .input-euro.right input {
    padding-right: 18px;
    text-align: end;
  }

  .input-euro:before {
    position: absolute;
    top: 0;
    content: "€";
  }

  .input-euro.left:before {
    left: 5px;
  }

  .input-euro.right:before {
    right: 5px;
  }

  .scaleBloc {
    margin-bottom: 0;

    .labelBloc {
      color: black;
      margin-bottom: 10px;
    }

    input {
      color: #4A90E2;
      font-family: "Avenir Next LT Pro Bold", "Avenir Next LT Pro Regular", sans-serif;
    }
  }

  .historyTable {
    .ant-table-tbody {
      color: #1f5288;
    }
  }
}

.ag-theme-balham .ag-row-hover {
  background-color: transparent;
}

.rowBillings {
  display: flex;

  tbody {
    width: 100%;

    tr {
      display: block;
      line-height: 2.45rem;
      border-bottom: 1px solid slategray;
      padding-left: 1rem;
      width: 100%;
      color: #1f5288;
      font-size: 1.05rem;
      cursor: pointer;

      &:hover {
        background-color: #E0F2F2;
        color: black;
      }

      td {
        display: inline-block;

        &:first-child {
          width: 89%;
          text-transform: capitalize;
        }

        &:last-child {
          width: 10%;
          text-align: right;
        }
      }
    }
  }
}

.checkBoxAndTitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    display: inline-flex;
  }
}
