.starsContainer{
	width:90px;
	background:transparent;
	button{
		background:transparent;
		border:none;
		border-radius:0px;
		width:18px;
		padding:0px;
		margin:0px;
		&.fill{
			color:#f1c40f;
		}
		&.empty{
			color:rgba(0,0,0,0.5);
		}
		i{
			font-size:16px;
			margin-right:0px!important;
		}
	}
}