$bluev2: #1f5288;

.modal-overlay {
  overflow-x: hidden;
}

.mission-modal {
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  width: 720px;
  padding: 10px 50px;
  border-radius: 0.4%;
  overflow: hidden;

  .mission-ids-list {
    font-size: 14px;
    margin: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .mission-title {
    align-items: center;
    font-size: 1.6rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $bluev2;
    padding-top: 20px;
  }

  .mission-subtitle {
    height: 19px;
    margin: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    width: 100%;
  }
  
  .mission-date-subcontainer{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 10px;
  }

  .mission-date-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 4%;
  }

  .mission-inputs-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .mission-select {
      display: flex;
      flex-direction: row;
      flex-wrap: no-wrap;
      justify-content: center;
      box-sizing: border-box;
      div {
        margin: 2px 3px;
        text-align: center;
      }
    }
    .mission-skills-select {
  
      width: 96%;
      div {
        margin: 2px 3px;
        text-align: center;
      }
    }
    .mission-input-subcontainer {
      width: 45%;
      margin: 10px;
      display: flex;
      flex-direction: column;
    }

    .mission-label {
      font-size: 14px;
      line-height: 30px;
      color: $bluev2;
      text-align: left;
      padding: 0 10;
    }

    .date-labels {
      font-size: 14px;
      line-height: 30px;
      color: $bluev2;
      padding: 0px 26px;

    }
    .mission-input {
      height: 36px;
      padding-left: 5px;
      margin: 6px 0 0;
      border-radius: 5px;
      border: solid 1px #979797;
      font-size: 1rem;
    }

    .mission-input-number {
      height: 36px;
      padding-left: 5px;
      margin: 6px 0 0;
      border-radius: 5px;
      border: solid 1px #979797;
      font-size: 1rem;
      width: 100%;
    }

    .mission-input-large {
      width: 230px;
    }

    .mission-input-mail {
      height: 36px;
      padding-left: 5px;
      margin: 6px 0 0;
      border-radius: 5px;
      border: solid 1px #979797;
      font-size: 0.9rem;
    }
    .mission-text-area-subcontainer {
      width: 93%;
    }
    .mission-text-area {
      height: 100px;
    }
    .mission-price-range-container {
      padding: 10px;
      width: 100%;
    }

    .mission-price-range-subcontainer {
      padding: 5px;
    }

    .mission-price-range {
      height: 36px;
      padding-left: 5px;
      margin: 0 auto;
      border-radius: 5px;
      border: solid 1px #979797;
      font-size: 1rem;
      width: 40%;
    }

    .mission-pic-name {
      width: 95%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      padding: 2px 6px;
      margin: 1px 10px;
  }

    .mission-date {
      width: 90%;
      font-size: 1.2rem;
    }

    .excluded-days {
      display: flex;
      align-items: baseline;
    }

    .excluded-span {
      border: 1px solid grey;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 1rem;
      width: 100%;
      margin: 5 auto;
    }

    .mission-address {
      height: 36px;
      padding-left: 5px;
      margin-top: 2.5px;
      border-radius: 5px;
      border: solid 1px #979797;
      font-size: 1rem;
      z-index: 0;
    }

    .mission-billing {
      width: 30%;
    }

    .mission-status {
      width: 100%;
    }

    .mission-cancel {
      min-width: 120%;
      height: 150px;
    }
  }
  .weekly-calendar-container {
    width: 100%;
    margin-top: 7px;
    margin-left: -30px;

    .weekly-calendar {
      margin-bottom: 5px;

      .weekly-calendar-inputs {
        width: 100%;
        display: flex;
        align-items: baseline;

        .weekly-calendar-labels {
          margin: 15px;
          font-size: 0.9rem;
          text-align: center;
          max-width: 25px;
        }
      }
    }
  }
}
