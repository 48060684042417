.paginator {
	ul {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		margin-left: auto;
		margin-right: auto;
		border-radius: 4px;
		justify-content: center;

		li {
			list-style-type: none;
			text-align: center;
			font-size: 12px;
			background: white;
			border: 1px solid rgba(0, 0, 0, 0.08);
			padding: 8px 12px;
			border-right: none;
			cursor: pointer;

			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border-right: 1px solid rgba(0, 0, 0, 0.08);
			}

			&:hover,
			&.active {
				color: white;
				background: $blueNew;
			}
		}
	}
}
