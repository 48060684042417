$normalInputHeight: 30px;

.formInput {
  label {
    font-family: Gilroy, 'sans-serif';
  }

  margin-bottom: 15px;
  font-size: 11px;

  &.flexbox {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    label:nth-child(2) {
      padding-top: 5px;
    }
  }

  &.withSearch {
    i {
      display: inline-block;
      padding: 5px;

      &:hover {
        color: red;
        cursor: pointer;
      }
    }

    p {
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.center {
    text-align: center;
  }

  label {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    // font-weight: bold;
    color: $greyNew;
    // padding-left:4px;
    margin-bottom: 3px;
    display: block;
  }

  input[type='email'],
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='phone'],
  input[type='date'] {
    background: transparent;
    padding-left: 2rem;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
    font-size: 12px;
    color: black;
    height: $normalInputHeight;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  input:disabled {
    background: #dddddd;
  }

  input[type='checkbox'] {
    margin-right: 10px;
    font-size: 12px;
  }

  input[type='radio'] {
    margin-right: 5px;
  }

  .radioValueText {
    margin-right: 12px;
    font-size: 12px;
  }

  textarea {
    color: black;
    width: 100%;
    max-width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
  }

  &.sendNotifications {
    input[type='text'],
    textarea {
      max-width: 1200px;
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.4);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.4);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.4);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.4);
}

select {
  &.noBorder {
    border: 0;
  }
}

p {
  &.noMargin {
    margin: 0;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;

  &.center {
    justify-content: center;
    align-items: center;
  }
}

.input-button-fre {
  padding: 15px;
  padding-right: 0;
  // width: 85px;
  margin: 3px;
  border-radius: 6px;
  background-color: #f8f8f8;
  border: solid 1px #ebebeb;
  height: auto;
}

.ant-collapse {
  background: transparent;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: transparent;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
