.magicUpload{
	// height:30px;
	// line-height: 34px;
	// font-size:11px;
	padding-top:0px;
	padding-bottom:0px;
	margin-top:auto;
	margin-bottom:auto;
	i{
		padding-bottom:0px;
		margin-bottom:0px!important;
	}
}