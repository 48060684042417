.userHeader {
  display: table;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  // border-left: 4px solid transparent;
  box-sizing: border-box;

  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 32px;
  margin-bottom: 10px;

  // .item {
  // display: table-cell;
  // font-size: 10px;
  // text-transform: uppercase;
  // text-align: center;
  // font-weight: bold;
  // color: $firstColor;
  // letter-spacing: 1px;
  // height: 32px;
  // line-height: 32px;
  // padding:
  // }
  div {
    display: table-cell;
    font-weight: bold;
    color: black;
    font-size: 9px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    padding: 9px;
    box-sizing: border-box;
    text-align: left;

    &.id {
      width: 60px;
    }

    &.date {
      width: 150px;
    }

    &.name {
      width: 170px;
    }

    &.email {
      width: 230px;
    }

    &.phone {
      width: 90px;
    }

    &.experienceYear {
      width: 70px;
      text-align: center;
    }

    &.entreprise {
      width: 150px;
    }

    &.city {
      width: 130px;
    }

    &.birthdate {
      width: 150px;
    }

    &.year {
      width: 100px;
    }

    &.address {
      width: 100px;
    }

    &.actions {
      // width: 200px;
      text-align: right;
    }
  }
}

.usersContainerList {
  padding: 0px;
  margin: 0px;

  >div {
    width: 100%;
    background: white;
    list-style-type: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    // border-left:4px solid transparent;
    box-sizing: border-box;
    // display:flex;
    // flex-direction:row;
    // align-items:flex-start;
    font-size: 12px !important;
    display: table;
    padding-top: 6px;
    padding-bottom: 6px;

    >div {
      padding: 5px;
      box-sizing: border-box;
      text-align: left;
      display: table-cell;
    }

    .date {
      width: 150px;
      text-align: left;
      color: $greyNew;
    }

    &:hover {
      // border-left-color: $blueNew;
      background: #efefef;

      .date {
        color: $blueNew;
      }
    }

    .id {
      width: 60px;

      i {
        color: $greyNew;
      }
    }

    .name {
      width: 170px;
    }

    .email {
      width: 230px;
      max-width: 230px;
      // white-space: nowrap;
      text-overflow: ellipsis;
    }

    .experienceYear {
      width: 70px;
      text-align: center;
    }

    .phone {
      width: 90px;
    }

    .entreprise {
      width: 160px;
    }

    .birthdate {
      width: 150px;
    }

    .year {
      width: 70px;
    }

    .address {
      width: 400px;
    }

    .actions {
      >div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        >button {
          background: transparent;
          font-size: 11px;
          border: none;
          cursor: pointer;
          text-align: center;
          color: $grey2New;
          margin-left: 10px;
          margin-right: 10px;

          >i {
            // padding-top: 10px;
            font-size: 12px;
            margin-left: 0px !important;
            margin-right: 0px !important;
            margin-bottom: 10px;
          }

          &:hover {
            color: $blueNew;
          }

          &.folderContainer {
            position: relative;

            // z-index:1;
            &:hover {
              z-index: 9999;
            }

            .folderContainerItem {
              display: none;
              position: absolute;
              top: 0px;
              left: -150px;
              background: white;
              width: 160px !important;
              overflow-x: scroll;
              border-radius: 4px;
              height: 120px;
              z-index: 10;
              margin-bottom: 10px;

              &.big {
                height: 186px;
              }

              @extend .boxshadow;

              div.item {
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 10px;
                color: $greyNew;
                cursor: pointer;
                font-size: 12px;
                text-align: left;
                height: 30px;
                line-height: 30px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.075);
                display: flex;

                a {
                  color: $blueNew;
                  flex: 1;
                }

                .item_content {
                  flex: 1;
                }

                .magicUpload {
                  align-self: flex-end;
                  margin-top: 6px;
                  padding-right: 5px;
                }

                button {
                  float: right;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  color: $blueNew;
                }

                &.itemWithButton {
                  button {
                    color: $greyNew;
                    text-align: left;
                    font-size: 12px;
                    border: none;
                    width: 100%;

                    &:hover {
                      color: $blueNew;
                    }
                  }
                }
              }
            }

            &:hover {
              .folderContainerItem {
                display: block;
                z-index: 10;
              }
            }
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.noUsers {
      font-size: 13px;
      text-align: center;
    }
  }
}

.addSpecialist {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 50px;
  height: 50px;
  font-size: 25px;
  color: white;
  background: $blueNew;
  border: 1px solid $blueNew;
  border-radius: 50%;
  z-index: 999999;
  @extend .boxshadow;

  i {
    margin: 0px !important;
    padding: 0px !important;
  }

  &:hover {
    background: white;
    border: 1px solid $blueNew;
    color: $blueNew;
  }
}

.paginationButton {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;

  button {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 35px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    &:hover {
      background: $blueNew;
      color: white;
      border: 1px solid $blueNew;
    }
  }
}