.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
.dialog-root {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: fixed;
  &.hidden {
    display: none;
  }
  .dialog {
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: opacity;
    opacity: 1;
    // max-width: 600px;
    min-width: 150px;
    min-height: 150px;
    padding: 15px;

    margin: 48px;
    margin-top: 135px;

    display: flex;
    outline: none;
    position: relative;
    overflow-y: auto;
    flex-direction: column;

    flex: 0 1 auto;
    max-height: calc(100% - 96px);

    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    .items-medium {
      display: flex;
      flex-direction: row;
      .item {
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    }
    .items-large {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        &.centered {
          text-align: center;
        }
        .logData {
          display: flex;
          flex-direction: column;
          .logData-item {
            padding: 3px;
            display: flex;
            justify-content: space-between;
            .date {
            }
            .value {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    will-change: opacity;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
