.verifyEmailPage{
	width:400px;
	margin-left: auto;
	margin-right: auto;
	padding:15px;
	border-radius:4px;
	background:white;
	margin-top:50px;
	@extend .boxshadow;
	text-align:center;
	.verifyPart{
		margin-top:30px;
		&.step-2{
			font-size:13px;
			i{
				font-size:15px;
				margin-right:8px;
			}
		}
		&.step-3{
			i{
				font-size:20px;
				margin-bottom:10px;
			}
		}
	}
	form{
		text-align:left;
		width:350px;
		margin-top:30px;
		padding:0px 20px;
		margin-left: auto;
		margin-right: auto;
		font-size:12px;
	}
	input[type="checkbox"]{
		margin-right:6px;
	}
	.helper{
		font-size:13px;
		text-align:center;
	}
	.formButton{
		margin-top:20px;
	}
	.linkContainer{
		width:350px;
		margin-left:auto;
		margin-right:auto;
		display:flex;
		flex-direction:column;
		text-align: left;
		padding-top:20px;
		a{
			margin-bottom:10px;
			font-size:13px;
			color:$greyNew;
			&:hover{
				color:$blueNew;
			}
		}
	}
}