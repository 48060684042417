.concession {
  &.mtp {
    padding: 10px;
  }
  &.mtp2 {
    padding: 20px;
  }
  .table {
    background: white;
    border-radius: 10px;
    .table-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;
      .search {
        padding: 10px;
        i {
          font-size: 19px;
        }
      }
      .refresh {
        flex-direction: row;
        display: flex;
        align-items: center;
        i {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 20px;
          font-size: 19px;
          &:hover {
            color: #b6bbc6;
            cursor: pointer;
          }
        }
        p {
          margin-right: 5px;
        }
      }
      .menu {
        position: relative;
        // padding: 20px;
        font-size: 20px;
        i:hover + .toggle-menu {
          display: inherit;
          // opacity: 1;
        }
        i {
          padding: 20px;
          padding-left: 5px;
          color: #1f5288;
          &:hover {
            color: grey;
            cursor: pointer;
          }
        }
        // i, &:hover {
        //   display: inherit;
        // }
        .toggle-menu {
          z-index: 10;
          display: none;
          transition: opacity 0.3s ease-in-out;
          &:hover {
            display: inherit;
          }
          top: 40px;
          position: absolute;
          min-width: 200px;
          // min-height: 200px;
          background: white;

          padding: 0px 0;
          // padding: 10px 0;
          margin-top: 15px;
          -webkit-box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
          box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
          border: none;
          text-align: left;
          list-style: none;
          border-radius: 0.25rem;
          &.left {
            left: calc(100% - 193px);
          }
          li {
            &:hover {
              background: #f2f5f8;
              cursor: pointer;
            }
            // padding-left: 24px;
            padding-right: 15px;
            padding-bottom: 4px;
            padding-top: 4px;
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            // span {
            //   display: flex;
            //   flex-direction: row;
            //   // justify-content: space-between;
            //   align-items: center;
            // }
            i {
              padding: 15px;
              width: 25px;
              height: 25px;
              font-size: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            p {
              display: -webkit-inline-box;
              display: inline;
            }
          }
        }
      }
    }
    .table-header {
      color: #1f5288;
      border-bottom: 1px solid #f1f1f1;
      padding-left: 30px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          &:first-child {
            margin-bottom: 0px;
          }
          &:last-child {
            margin-top: 0px;
          }
        }
      }
    }
    .table-search {
      // height: 70px;
      // background: #f9f9f9;
      background: #ebebeb;
    }
    .table-content {
      color: #1f5288;
      margin-left: 10px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      .table-row-header {
        border-bottom: solid 1px #dee1e7;
        display: inherit;
        flex-direction: row;
        width: 100%;
        div {
          transition: border 0.2s linear;
          border-bottom: solid 3px transparent;
          &.selected {
            border-bottom: solid 3px #1f5288;
          }
          // &:hover {
          //   background: #f2f5f8;
          //   cursor: pointer;
          // }
          display: flex;
          flex-direction: row;
          justify-content: center;
          // justify-content: space-around;
          align-items: center;
          i {
            font-size: 20px;
          }
        }
      }
      .table-rows {
        .table-row {
          display: flex;
          flex-direction: row;
          padding: 10px;
          align-items: center;
          div {
            text-align: center;
          }
          .action {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            div {
              padding: 10px;
              &:hover {
                cursor: pointer;
                color: #8ea9c7;
              }
            }
          }

          border-top: solid 1px #efefef;
          // &:nth-child(odd) {
          //   background: #1f52880f;
          // }
          // &:nth-child(even) {
          //   background: white;
          // }
          &:last-child {
            border-bottom: solid 1px #efefef;
          }
          &:hover {
            // background: #1f52884a;
            background: #e8e8e84a;
            // cursor: pointer;
            // &:nth-child(even) {
            //   background: #f1f2fa;
            // }
            // &:nth-child(odd) {
            //   background: white;
            // }
          }
        }
      }
    }
    .table-responsive {
      // display: block;
      // width: 100%;
      // overflow-x: auto;
      // -webkit-overflow-scrolling: touch;
      // -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  .card {
    border-radius: 15px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    transition: all 0.5s ease-in-out;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    display: flex;
    position: relative;
    margin-top: 10px;
  }
}

.card {
  padding-left: 0px;
  padding-right: 0px;
}
.search-container {
  color: #676767;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .search-item {
    flex: 1;
    display: inherit;
    flex-direction: column;
    padding: 10px;
    // padding-left: 0px;
    .search-input {
      position: relative;
      &.max {
        width: 100%;
      }
      &.daterange {
        &:hover {
          cursor: pointer;
        }
        .input {
          display: flex;
          flex-direction: row;
          flex: 1;
        }
      }
      &.icon {
        i {
          padding: 15px;
          background: white;
          border-radius: 10px 0px 0px 10px;
        }
        input {
          border-radius: 0px 10px 10px 0px;
          &.daterange {
            cursor: pointer;
          }
        }
      }
      padding: 10px;
      padding-left: 0px;
      display: inherit;
      flex-direction: row;
      input {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        border: solid 0px;
        // padding-left: 30px;
      }
      .rDrDateRangeWrapper {
        top: 50px;
        position: absolute;
        border: solid 1px #d6d6d6;
        .rdrPprevButton,
        .rdrNextButton {
          // i {
          //   border: 0;
          // }
          display: none;
        }
      }
    }
  }
}
.multi-select {
  width: 100%;
  // &.p6px {
  //   input {
  //     padding: 6px!important;
  //   }
  // }
}
.segmented-bar {
  display: flex;
  &.row {
    flex-direction: row;
  }
  flex-direction: column;
  border-bottom: solid 1px #ebebeb;
  &.m-b-30 {
    margin-bottom: 30px;
  }
  &.m-b-5 {
    margin-bottom: 5px;
  }
  div {
    width: fit-content;
    &.selected {
      border-bottom: solid 3px #1f5288;
    }
    cursor: pointer;
    p {
      margin-bottom: 10px;
    }
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
