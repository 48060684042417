.inspectionContainerGlobal {
  margin-top: 30px;
  display: flex;
  flex-direction: row;

  .inspectionContainerParent {
    flex: 1;
    padding: 10px;

    .inspectionContainerParentContent {
      .inspectionContainerNumber {
        font-size: 25px;
        font-weight: bold;
        // color:$greyDark;
        color: $orange;
      }

      .inspectionContainerTitle {
        text-transform: uppercase;
        margin-top: 4px;
        // color:$greyDark;
        letter-spacing: 1px;
        color: $orange;
      }

      ul.inspectionContainerList {
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.075);
          border-bottom: 2px solid rgba(0, 0, 0, 0.15);
          padding: 20px 20px;
          margin-top: 8px;
          border-radius: 4px;
          font-size: 12px;
          // color:#434343;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;

          .inspectionContainer_download {
            padding-right: 20px;
            text-align: center;
            cursor: pointer;

            a {
              color: $blueDark;
              text-decoration: none;

              &:hover {
                color: $orange;
              }

              i {
                font-size: 20px;
              }

              div {
                padding-top: 4px;
                font-size: 10px;
              }
            }
          }

          .inspectionContainerMainContent {
            flex: 1;
            min-height: 40px;
            color: #434343;

            i {
              margin-right: 5px;
            }

            div {
              &.line {
                margin-bottom: 2px;
                display: flex;
                flex-direction: row;

                .lineItem {
                  flex: 1;
                  margin-bottom: 8px;

                  .lineItemTitle {
                    color: rgba(0, 0, 0, 0.35);
                    font-size: 12px;

                    // text-transform: uppercase;
                    // letter-spacing: 1px;
                    i {
                      font-size: 13px;
                    }

                    padding-bottom: 4px;
                  }
                }
              }
            }

            .inspectionContainerMainContentButton {
              // height:30px;
              padding-top: 5px;
              padding-bottom: 5px;
              // background:rgba(0,0,0,0.05);
              background: $blueDark;
              padding-left: 20px;
              position: absolute;
              bottom: -2px;
              left: 0px;
              right: 0px;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              font-size: 11px !important;
              visibility: hidden;
            }
          }

          &:hover {
            background: rgba(123, 190, 250, 0.2);

            div.line {
              .lineItemValue {
                color: $blueDark;
              }
            }

            .inspectionContainerMainContentButton {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.inspectionId {
  width: 50px;

  i {
    margin-left: 6px;
    color: $blueNew;
    font-size: 16px;
    font-size: 14px;

    &.fa-exclamation-triangle {
      color: red;
    }

    &.fa-check-square {
      color: $greyDark;
    }

    &.fa-square {
      color: $greyDark;
    }
  }

  .customBadge {
    margin-top: 8px;
    border: 1px solid $blueNew;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 26px;
    font-weight: bold;
    font-size: 12px;
    color: $blueNew;
  }

  img {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.inspectionNotationDescription {
  font-size: 12px;
  font-style: italic;
  padding-top: 4px;
  padding-bottom: 8px;
  max-width: 480px;
}

.inspectionSpecialContent {
  width: 560px;
}

.inspectionDate {
  width: 120px;
}

.inspectionCar {
  width: 80px;
}

.inspectionModel {
  width: 80px;
}

.inspectionImmat {
  width: 80px;
}

.inspectionSpecialist {
  width: 80px;
}

.inspectionPro {
  width: 120px;
}

.inspectionCompany {
  width: 100px;
}

.inspectionSellerName {
  width: 100px;
}

.inspectionSellerPhone {
  width: 100px;
}

.inspectionGroupName {
  width: 120px;
}

.inspectionSiteName {
  width: 120px;
}

.inspectionAdress {
  // width: 250px;
  flex: 1;
  line-height: 14px;
}

.inspectionDetails {
  box-shadow: none;

  .topContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 8px;

    .topContainerMainElement {
      flex: 1;
      padding: 5px 0 10px 0;
      margin: 0 20px 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .inspectionDetailsId {
        color: rgba(0, 0, 0, 0.8);
        font-size: 15px;
      }

      .inspectionDetailsStatus {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.8);

        span {
          margin-left: 5px;
        }
      }

      .inspectionDetailsDate {
        color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
      }

      .inspectionDetailsDownload {
        a {
          font-size: 12px;
          color: $blueNew;

          i {
            font-size: 13px;
            margin-right: 4px;
          }
        }
      }
    }

    .close {
      margin: 8px 5px 0 0;
      font-size: 15px;
      background: transparent;
      border: none;
      color: rgba(0, 0, 0, 0.8);

      &:hover {
        color: $blueNew;
      }
    }
  }

  .inspectionDetailsContent {
    .smallCaps {
      color: rgba(0, 0, 0, 0.582);
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 3px;
    }

    .text {
      font-size: 12px;
      color: $blueNew;

      a {
        text-decoration: underline;
      }
    }

    .inspectionDetailsContentInfos {
      padding: 0 0 4px 0;
      margin: 0 0 5px 0;

      li {
        list-style-type: none;
        width: 33%;
        display: inline-table;
        vertical-align: top;
        text-align: left;

        &.oneItems {
          width: 100%;
        }

        &.twoItems {
          width: 50%;
        }

        &.threeItems {
          width: 33%;
        }

        &.forItems {
          width: 25%;
        }
      }
    }

    // height:700px;
  }

  .commentsTitle {
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    color: $blueNew;

    // color:#08387A;
    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .commentsContainer {
    display: flex;
    // padding: 10px;
    flex-direction: column;

    .commentsList {
      // padding: 10px;
      padding-bottom: 0px;
      margin-bottom: 5px;

      ul {
        margin: 0px;
        padding: 0px;
        max-height: 250px;
        overflow-y: scroll;

        li {
          &.noComment {
            font-size: 12px;
          }

          list-style-type: none;
          margin-bottom: 10px;

          .commentsListLeft {
            width: 45px;
            display: table-cell;
            font-size: 11px;
            text-align: center;
            vertical-align: top;

            img {
              width: 40px;
              border-radius: 4px;
              margin-bottom: 4px;
            }

            i {
              padding-top: 5px;
              color: rgba(0, 0, 0, 0.4);
              font-size: 30px;
            }

            div {
              width: 45px;
            }
          }

          .commentsListMessage {
            width: calc(100% - 110px);
            vertical-align: top;
            display: table-cell;
            color: rgba(0, 0, 0, 0.85);
            font-size: 13px;
            padding-left: 8px;

            .message {
              font-size: 12px;
            }

            .timestamp {
              margin-top: 4px;
              margin-top: 3px;
              font-size: 11px;
              color: rgba(0, 0, 0, 0.6);
            }

            .cancelReason {
              margin-top: 4px;
              margin-top: 3px;
              font-size: 11px;
              color: red;
            }
          }
        }
      }
    }

    .messageBoxPart {
      padding: 0px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      border-top-radius: 4px;

      textarea {
        height: 70px;
        border: none;
        vertical-align: top;
        flex: 1;
        font-size: 12px;
        border-bottom-left-radius: 4px;
        padding: 3px;
      }

      button {
        vertical-align: top;
        text-align: center;
        height: 70px;
        width: 80px;
        border: none;

        &:hover {
          color: $blueNew;
        }

        &.disabled {
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }

  @extend .boxshadow;
  // border:1px solid rgba(0,0,0,0.2);
}

// Modal inspection creation
.modalInspectionCreation {
  width: 690px;
  padding: 20px;
}

.inspectionModalPart2,
.inspectionModalPart3 {
  img {
    width: 60px;
  }
}

.smallCaps {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 3px;
  margin-top: 10px;
}
