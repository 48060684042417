$firstColor: #0a4865;
$silver: #bdc3c7;
$commentColor: #6c7a89;
$red_error_color: #e33c2c;

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

.specialistFiche {
  .specialistFicheClose {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    border: none;
    cursor: pointer;
    background: transparent;
    color: $commentColor;
    &:hover {
      color: $firstColor;
    }
  }
  .specialistFicheFirstLine {
    display: flex;
    flex-direction: row;
    padding: 10px 5px 0 10px;
    font-size: 1.05rem;
    .specialistFicheFirstLineLeft {
      flex: 1;
      .specialistFicheName {
        font-weight: bold;
        font-size: 13px;
        color: $firstColor;
        margin-bottom: 4px;
      }
      .specialistFicheCity {
        font-size: 12px;
        color: $commentColor;
        text-overflow: ellipsis;
        width: 300px;
        overflow: hidden;
      }
      .specialist-availability {
        color: $commentColor;
        font-size: 12px;
      }
    }
    .specialistFicheFirstLineRight {
      text-align: center;
      color: $firstColor;
      .specialistFicheInspectionCount {
        font-size: 20px;
        font-weight: bold;
      }
      .specialistFicheInspectionCountLabel {
        font-size: 12px;
        text-align: center;
      }
      width: 90px;
    }
  }
  .specialistFicheInscriptionDate {
    color: $commentColor;
    font-size: 12px;
    // padding: 5px;
    display: flex;
    flex-direction: row;
    div {
      &:first-child {
        text-align: left;
        margin-right: 20px;
      }
    }
  }
  .specialistButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    margin-bottom: 10px;
    button {
      flex: 1;
      border-radius: 0px;
      border: none;
      height: 35px;
      line-height: 35px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      &.edit {
        background: #0f6e9b;
        color: white;
        &:hover {
          background: #16a1e2;
        }
      }
      &.status {
        background: #16a1e2;
        color: white;
        &:hover {
          background: #0f6e9b;
        }
      }
    }
  }
  .specialistFormButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    button {
      padding: 2px 9px;
      margin-left: 4px;
      margin-right: 4px;
    }
    .specialistFormButtonCancel {
      background: white;
      border: 1px solid $commentColor;
      color: $commentColor;
      font-size: 12px;
      border-radius: 15px;
      &:hover {
        background: $commentColor;
        color: black;
      }
    }
    .specialistFormButtonSubmit {
      background: $firstColor;
      border: 1px solid $firstColor;
      color: white;
      font-size: 12px;
      border-radius: 15px;
      &:hover {
        background: white;
        color: $firstColor;
      }
    }
  }
  .specialistFichePart {
    .caution {
      text-align: center;
      color: red;
      i {
        margin-right: 5px;
      }
    }
    .ant-select-multiple.ant-select-sm .ant-select-selection-item {
      color: black;
      height: 20px;
      line-height: 17px;
      background-color: #fff;
      font-size: 12px;
    }

    .mobileVersion {
      div {
        margin-right: 1rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        &.outdated {
          background: red;
          color: white;
        }
      }
    }
    .specialistFichePartTitle {
      font-weight: bold;
      font-size: 13px;
      color: $firstColor;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      .kycdocs {
        text-align: right;
      }
      div {
        flex: 1;
      }
      button {
        font-size: 11px;
        background: $firstColor;
        color: white;
        border: 1px solid $firstColor;
        border-radius: 25px;
        text-transform: lowercase;
        cursor: pointer;
        &:hover {
          background: white;
          color: $firstColor;
        }
      }
    }
    .specialist-availability {
      &__list {
        display: block;
      }

      .list {
        &__item {
          margin-bottom: 10px;
        }
      }

      li {
        margin: 0;
      }
    }
    .specialistFicheComment {
      font-size: 12px;
      padding-left: 10px;
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      .professionalExperience {
        display: block;
        width: 100%;
        position: relative;

        &:not(:last-child) {
          margin: 8px 0;
          border-bottom: 1px solid lightslategray;
        }
      }
      li {
        list-style-type: none;
        margin-bottom: 10px;
        font-size: 13px;
        width: 50%;
        &.full {
          width: 100%;
        }
        &.w30 {
          width: 30%;
        }
        &.w40 {
          width: 40%;
        }
        label {
          color: #b5b5b5;
          margin-bottom: 3px;

          &.red {
            color: $red_error_color
          }
        }
        div {
          color: $blueDark;
        }
        input[type="text"] {
          margin-top: 3px;
          border-radius: 4px;
          height: 25px;
          line-height: 25px;
          font-size: 12px;
          padding-left: 3px;
          padding-top: 0;
          padding-bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.15);
          width: calc(100% - 20px);
          margin-bottom: 5px;
        }
        select {
          margin-top: 3px;
          border-radius: 4px;
          height: 25px;
          line-height: 25px;
          font-size: 12px;
          padding-left: 3px;
          padding-top: 0;
          padding-bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.15);
          width: calc(100% - 20px);
          margin-bottom: 5px;
        }
        textarea {
          height: 70px;
          margin-top: 3px;
          border-radius: 4px;
          font-size: 12px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          width: calc(100% - 20px);
          margin-bottom: 5px;
        }
      }
    }

    .documents {
      margin-top: 20px;
    }

    .label {
      color: $commentColor;
      font-size: 12px;
    }

    .proposition-slots {
      color: black;
      font-size: 12px;
    }
  }
  .specialistSkills {
    ul {
      li {
        width: auto;
      }
    }
  }
  .specialistSkills > .ant-select-selection__rendered::after {
    content: '';
  }
  .documents {
    li {
      flex: 1;
    }
  }
  .specialistFichePartUploadContainer {
    width: 100%;
    position: relative;
    .buttonLoadDocument {
      margin-right: 10;
    }
    button {
      background: none;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      color: $commentColor;
      border: none;
      .specialistFichePartUploadLibelle {
        color: $commentColor !important;
      }
      div {
        font-size: .95rem;
        font-weight: bold;
        color: $commentColor;
      }
      i {
        font-size: 20px;
      }
      &.fill {
        color: #1bbc9b;
        .specialistFichePartUploadLibelle {
          color: #1bbc9b !important;
        }
      }
    }
    .specialistFichePartUploadMenu {
      display: block;
      cursor: pointer;
      padding-top: 4px;
      text-align: center;
      button {
        border-radius: 25px;
        text-transform: lowercase;
        width: 65px;
        text-align: center;
        font-size: .9rem;
        color: $commentColor;
        border: 1px solid $commentColor;
        &:hover {
          color: #1bbc9b;
          border-color: #1bbc9b;
        }
      }
    }
  }
  .specialistStatusDocumentsAndInspectionTest {
    .viewer {
      span {
        margin: 1.5rem;
        font-size: 1.0rem;

        i {
          color: $firstColor;
          padding-right: .3rem;
        }
      }
    }
  }
}

.statusBadge {
  font-size: 11px;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  padding: 1px 12px;
  font-weight: bold;
  display: inline-block;
  &.actif {
    border-color: #19b5fe;
    background: #19b5fe;
    color: white !important;
  }
  &.confirme {
    border-color: #446cb3;
    background: #446cb3;
    color: white !important;
  }
  &.non_confirme {
    border-color: #f2784b;
    background: #f2784b;
    color: white !important;
  }
  &.nouveaux {
    border-color: #61E786;
    background: #61E786;
    color: white !important;
  }
  &.inactifs {
    border-color: #1d2325;
    background: #1d2325;
    color: white !important;
  }
  &.aucun {
    border-color: #6c7a89;
    background: #6c7a89;
    color: white !important;
  }
  &.bloques {
    border-color: #ef4836;
    background: #ef4836;
    color: white !important;
  }
  &.inactive-reason {
    border-color: #1d2325;
    background: #446cb3;
    color: white !important;
    margin: 1 1;
    width: max-content;
  }
}
.icon-circle {
  font-size: 1.2rem;
  padding-left: 5px;
  color: #676062;
  .true {
    color: #19b5fe;
  }
}

.grayLabel {
  color: $greyNew;
}
