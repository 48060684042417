.apidocumenation{
	width:100%;
	max-width:900px;
	margin-left:auto;
	margin-right:auto;
	margin-top:20px;
	iframe{
		width:100%;
		height:auto;
		height:100%;
		border:1px solid rgba(0,0,0,0.075);
		border-radius:4px;
	}
}

.logsContainerList{
	width:800px;
	margin-left:auto;
	margin-right:auto;
	// border:1px solid $grey2New;
	border-radius:4px;
	@extend .boxshadow;
	margin-top:20px;
	padding:0px;
	li{
		width:100%;
		padding:20px;
		background:white;
		list-style-type: none;
		border-bottom:1px solid rgba(0,0,0,0.075);
		border-left:4px solid transparent;
		display:flex;
		flex-direction:column;
		.date{
			font-size:12px;
			width:150px;
		}
		.httpCode{
			width:100px;
		}
		.method{
			width:100px;
		}
		.endpoint{
			width: 200px;
		}
		.rowLine{
			display:flex;
			flex-direction:row;
			align-items:flex-start;
		}
		.libelle{
			color:$greyNew;
			font-size:9px;
			text-transform:uppercase;
			letter-spacing:1px;
			margin-bottom:5px;
		}
		.value{
			color:#434343;
			font-size:12px;
		}

		&:hover{
			border-left-color: $blueNew;
			.date{
				color:$blueNew;
			}
		}
		.logObject{
			margin-bottom:10px;
			.logObjectFirstLine{
				text-transform: uppercase;
				letter-spacing: 1px;
				color:$greyNew;
				button{
					border: none;
					font-size:13px;
					color:$greyNew;
					&:hover{
						color:$blueNew;
					}
				}
			}
			.logObjectContent{

			}
		}
		&.noLogs{
			text-align:center;
			font-size:13px;
		}
	}	
}