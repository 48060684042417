.specialistFiltersContainer {
  position: absolute;
  top: 85px;
  left: 5px;
  right: 5px;
  z-index: 99999999;
  background: white;
  @extend .boxshadow;

  .specialistFiltersRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 60px;
    align-items: flex-start;
    padding: 0px;

    &:last-child {
      border-bottom: none;
    }

    .googlePlacesAutoComplete {
      width: 100% !important;
    }

    .simpleElementLabel {
      // text-transform: uppercase;
      font-size: 11px;
      // letter-spacing: 1px;
      margin-bottom: 3px;
    }

    .simpleElementButton {
      width: 80px;
      padding: 0px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      height: 60px;
      box-sizing: border-box;

      &:last-child {
        border-right: none;
      }

      button {
        font-size: 11px !important;

        i {
          font-size: 14px !important;
          margin-bottom: 6px;
        }
      }
    }

    .simpleElement {
      flex: 1;
      padding: 5px 15px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      height: 60px;
      box-sizing: border-box;

      &:last-child {
        border-right: none;
      }
    }

    .fullElement {
      flex: 1;
      padding: 5px 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      height: 60px;
      box-sizing: border-box;

      &:last-child {
        border-right: none;
      }
    }

    .fullElementButton {
      flex: 1;
      padding: 0px;
      display: flex;
      flex-direction: row;
      height: 60px;
      box-sizing: border-box;
      border-right: 1px solid rgba(0, 0, 0, 0.15);

      &:last-child {
        border-right: none;
      }
    }

    .inputRadioContainer {
      display: flex;
      flex-direction: row;

      > div {
        margin-top: 6px;
        font-size: 12px;
        margin-right: 10px;

        input {
          margin-right: 4px;
        }
      }
    }

    .buttonElement {
      flex: 1;
    }

    button {
      width: 100%;
      height: 100%;
      margin: 0px;
      border: none;
      border-radius: 0px;
      font-size: 13px;

      i {
        font-size: 15px;
      }

      &.map {
        background: #19b5fe;
        color: #0a4865;

        &:hover {
          color: white;
        }
      }

      &.list {
        color: #19b5fe;
        background: #0a4865;

        &:hover {
          color: white;
        }
      }

      &.download {
        background: #f4b350;
        color: #926b30;

        &:hover {
          background: #926b30;
          color: #f4b350;
        }
      }

      &.search {
        background: #1bbc9b;
        color: #0d5e4d;

        &:hover {
          background: #0d5e4d;
          color: #1bbc9b;
        }
      }

      &.stats {
        background: #aea8d3;
        color: #674173;

        &:hover {
          background: #674173;
          color: white;
        }
      }

      &.reset {
        background: #dadfe1;
        color: #6c7a89;

        &:hover {
          background: #6c7a89;
          color: white;
        }
      }

      &.viewSearch {
      }
    }

    input[type='text'] {
      width: 100%;
      margin-bottom: 5px;
      font-size: 13px;
      padding-top: 0px;
      padding-bottom: 0px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 28px;
      line-height: 28px;
    }
  }
}
.search-input {
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

}
