.googlePlacesDetailsCard{
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  ul{
    li{
      list-style-type: none;
      text-transform: capitalize;
    }
  }
}