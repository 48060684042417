.container-sub-menu {
  background-color: #F8F8F8;

  &.true {
    margin-bottom: 2rem;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #525151;
    color: white;

    .main__menu {
      ul {
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin-bottom: 0;

        li {
          display: inline-block;
          padding: .4rem .5rem;
          margin-right: 3rem;

          &:last-child {
            border-right: 0;

            a {
              &:before {
                right: 0;
              }
            }
          }

          a, span {
            display: block;
            padding: .4rem 1.5rem;
            background: transparent;
            transition: all .2s ease;
            color: #B1B1B1;
            font-size: 1rem;
            cursor: pointer;

            &:before {
              left: 0;
              right: -1px;
              height: 3px;
              background: #701dbb;
              bottom: 0;
              transition: all .2s ease;
            }

            &:hover {
              color: #0b1624;
            }
            &.active {
              color: white;
              font-weight: 600;
              border-bottom: 1px solid white;
            }
          }
        }
      }
    }
  }

  &.concess {
    background-color: transparent;
    .wrapper {
      border-radius: 15px;
    }
  }
}


#menuToggle {
  display: block;
  position: absolute;
  top: 38px;
  right: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0 0;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  display: none;
  position: absolute;
  width: 300px;
  margin: -100px 0 0 0;
  padding: 125px 50px 50px;
  right: -100px;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu {
  a:last-child {
    li {
      border-radius: 0 0 0 30px;
    }
  }
}

#menu li {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  text-transform: initial;
  background-color: #fff;
}

#menuToggle input:checked~ul {
  display: block;
  transform: scale(1.0, 1.0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  opacity: 1;
}
