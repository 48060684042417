$firstColor: #0a4865;

ul.inspectionContainerList {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  // border:1px solid $grey2New;
  border-radius: 4px;
  @extend .boxshadow;
  margin-top: 20px;
  padding: 0px;
  &.admin {
    width: 95%;
  }
  li {
    padding: 20px;
    background: white;
    list-style-type: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    border-left: 4px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    .inspectionPassedDiv {
      position: absolute;
      top: 2px;
      left: 0px;
      right: 0px;
      text-align: center;
      color: #b20000;
      font-size: 10px;
    }
    &.inspectionPassed {
      background: #ffcccc;
    }
    .date {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: $greyNew;
      width: 100px;
      .smallDate {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $greyNew;
        font-size: 8px;
      }
      .date_day {
        font-weight: bold;
        font-size: 24px;
      }
      .date_month {
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1.2px;
      }
      .date_year {
        text-align: center !important;
      }
    }
    &:hover {
      border-left-color: $blueNew;
      .date {
        color: $blueNew;
      }
    }
    .containerColumn {
      display: flex;
      flex-direction: column;
      width: calc(100% - 100px);
      padding-left: 20px;
      .containerRow {
        display: flex;
        flex-direction: row;
        .libelle {
          color: $greyNew;
          font-size: 9px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }
        .id_value {
          width: 120px;
        }
        .professional_name {
          width: 130px;
        }
        .value {
          color: #434343;
          font-size: 12px;
        }
        .car {
          width: 140px;
        }
        .year {
          width: 70px;
        }
        .address {
          width: 350px;
        }
        .specialist {
          width: 100px;
        }
        .comment {
          margin-top: 15px;
          flex: 1;
        }
        .sellerName {
          margin-top: 15px;
          width: 120px;
        }
        .sellerPhone {
          margin-top: 15px;
          width: 130px;
        }
      }
    }
    .actions {
      width: 100px;
      height: 60px;
      color: $greyNew;
      text-align: center;
      // background:red;
      position: relative;
      .actionsHover {
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        i {
          margin-left: 8px;
        }
      }
      .actionsContainer {
        display: none;
        background: white;
        z-index: 10;
        @extend .boxshadow;
        position: absolute;
        border-radius: 4px;
        width: 170px;
        left: -80px;
        top: 0px;
        padding: 0px;
        margin-bottom: 25px;
        flex-direction: column;
        button {
          background: transparent;
          font-size: 11px;
          border: none;
          cursor: pointer;
          text-align: center;
          height: 35px;
          line-height: 35px;
          padding-left: 6px;
          color: $greyNew;
          width: 100%;
          text-align: left;
          border-radius: 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.075);
          &:last-child {
            border-bottom: none;
          }
          i {
            font-size: 12px;
            margin-left: 0px !important;
            margin-right: 5px !important;
            margin-bottom: 10px;
          }
          &:hover {
            color: $blueNew;
            background: rgba(0, 0, 0, 0.07);
          }
        }
        .magicUpload {
          height: 35px;
          line-height: 30px !important;
          // border-bottom: 1px solid rgba(0,0,0,0.075);
          color: $greyNew;
          text-align: left !important;
          font-size: 11px;
          i {
            line-height: 30px !important;
            font-size: 12px;
          }
          &:hover {
            color: $blueNew;
            // background:rgba(0,0,0,0.07);
          }
        }
      }
      &:hover {
        .actionsContainer {
          display: flex;
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &.noInspection {
      font-size: 13px;
      text-align: center;
    }
  }
}

ul.smallComments {
  margin: 0px;
  padding: 0px;
  max-height: 80px;
  overflow-y: scroll;
  li {
    list-style-type: none;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    .smallCommentsImage {
      flex-basis: 30px;
      // width:30px;
      font-size: 10px;
      text-transform: lowercase;
      margin-left: 3px;
      display: flex;
      flex-direction: column;
      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-bottom: 3px;
      }
    }
    .smallCommentsText {
      font-size: 12px;
      flex: 1;
    }
  }
}
.inspectionsContainerList {
  .inspectionsContainerInfo {
    padding: 10px;
    background: white;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .inspectionsContainerHeader {
    display: table;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    background: white;
    border-radius: 5px;
    margin-top: 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 32px;
    margin-bottom: 10px;
    margin-top: 120px;
    > div {
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .element {
      text-transform: uppercase;
      // text-align: center;
      font-weight: bold;
      font-size: 10px !important;
      color: #0a4865;
      letter-spacing: 1px;
    }
    .inspectionsContainerHeaderItem {
      display: table-cell;
      font-size: 10px;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      color: $firstColor;
      letter-spacing: 1px;
      height: 32px;
      line-height: 32px;
      &.inspectionsContainerHeaderId {
        width: 70px;
      }
      &.inspectionsContainerHeaderDate {
        width: 100px;
        // background: blue;
      }
      &.inspectionsContainerHeaderEmail {
        width: 250px;
      }
      &.inspectionsContainerHeaderStatus {
        width: 100px;
        // background: red;
      }
      &.inspectionsContainerHeaderExperienceYear {
        width: 60px;
      }
      &.inspectionsContainerHeaderCity {
        width: 140px;
      }
      &.inspectionsContainerHeaderPhone {
        width: 100px;
      }
      // &.inspectionsContainerHeaderComment{

      // }
    }
  }
}
.inspectionsFiltersContainer {
  position: absolute;
  top: 85px;
  left: 5px;
  right: 5px;
  z-index: 900;
  background: white;
  @extend .boxshadow;
  .inspectionsFiltersRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 60px;
    align-items: flex-start;
    padding: 0px;
    &:last-child {
      border-bottom: none;
    }
    .googlePlacesAutoComplete {
      width: 100% !important;
    }
    .simpleElementLabel {
      // text-transform: uppercase;
      font-size: 11px;
      // letter-spacing: 1px;
      margin-bottom: 3px;
    }
    .simpleElementButton {
      width: 80px;
      padding: 0px;
      // border-right: 1px solid rgba(0,0,0,0.15);
      height: 60px;
      box-sizing: border-box;
      &.large {
        width: 100%;
        border-right: 0;
        &.active {
          border-bottom: solid 3px #62889b;
        }
      }
      &:last-child {
        border-right: none;
      }
      button {
        font-size: 11px !important;
        i {
          font-size: 14px !important;
          margin-bottom: 6px;
        }
      }
    }
    .simpleElement {
      flex: 1;
      padding: 5px 15px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      height: 60px;
      box-sizing: border-box;
      &:last-child {
        border-right: none;
      }
    }
    .fullElement {
      flex: 1;
      // padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      height: 60px;
      box-sizing: border-box;
      &:last-child {
        border-right: none;
      }
    }
    .fullElementButton {
      flex: 1;
      padding: 0px;
      display: flex;
      flex-direction: row;
      height: 60px;
      box-sizing: border-box;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      &:last-child {
        border-right: none;
      }
    }
    .inputRadioContainer {
      display: flex;
      flex-direction: row;
      > div {
        margin-top: 6px;
        font-size: 12px;
        margin-right: 10px;
        input {
          margin-right: 4px;
        }
      }
    }
    .buttonElement {
      flex: 1;
    }
    button {
      width: 100%;
      height: 100%;
      margin: 0px;
      border: none;
      border-radius: 0px;
      font-size: 13px;
      i {
        font-size: 15px;
      }
      &.linear-1 {
        background: linear-gradient(to left, #19b5fe, #63bbe6);
      }
      &.white {
        background: transparent;
        color: #205873;
        &:hover {
          color: #072e41;
          background: transparent;
        }
      }
      &.white--disabled {
        background: transparent;
        color: #a9a9a9;
      }
      &.map {
        background: #19b5fe;
        color: #0a4865;
        &:hover {
          color: white;
        }
      }
      &.list {
        color: #19b5fe;
        background: #0a4865;
        &:hover {
          color: white;
        }
      }
      &.download {
        background: #f4b350;
        color: #926b30;
        &:hover {
          background: #926b30;
          color: #f4b350;
        }
      }
      &.search {
        background: #1bbc9b;
        color: #0d5e4d;
        &:hover {
          background: #0d5e4d;
          color: #1bbc9b;
        }
      }
      &.stats {
        background: #aea8d3;
        color: #674173;
        &:hover {
          background: #674173;
          color: white;
        }
      }
      &.reset {
        background: #dadfe1;
        color: #6c7a89;
        &:hover {
          background: #6c7a89;
          color: white;
        }
      }
    }
    input[type="text"] {
      width: 100%;
      margin-bottom: 5px;
      font-size: 13px;
      padding-top: 0px;
      padding-bottom: 0px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 28px;
      line-height: 28px;
    }
  }
}
.noScroll {
  overflow-y: unset;
}

.inspectionRow {
  &:hover {
    background-color: #e9f0f4;
  }
}

.actionsContainer {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f5f7f7;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  border-top: 1px solid #bdc3c7;

  .quickFilterInput {
    border-width: 1px;
    border-style: solid;
    border-color: #95a5a6;
    margin-right: 10px;
  }

  span.separator {
    border-style: solid;
    border-color: #bdc3c7;
    margin-left: 15px;
    margin-right: 15px;
    border-right-width: 1px;
    border-width: 0px 1px 0px 0px;
  }

  span.hideColumnInput {
    color: rgba(0, 0, 0, 0.54);
    margin-right: 15px;
    i {
      margin-right: 2px;
    }
  }
}

.editionCell {
  // https://www.ag-grid.com/javascript-grid-cell-styling/
  overflow: visible;
  border: none !important;
  outline: none;

  .ag-cell-value {
    overflow: visible;
  }
}

.menu {
  width: 20px;
  text-align: center;
  i {
    font-size: 16px;
  }
  position: relative;
  .btnContainer {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    // right: 22px;
    width: 160px;
    background: white;
    border-radius: 4px;
    @extend .boxshadow;
    text-align: left;
  }
  &:hover {
    .btnContainer {
      display: block;
      z-index: 10;
      .btnContainerItem {
        background: transparent;
        text-align: left;
        padding-top: 7px;
        padding-bottom: 7px;
        &.bg-white {
          button {
            background: white;
          }
        }
        a {
          padding-left: 8px;
          text-decoration: none;
        }
        a,
        button {
          font-size: 11px;
          width: 100%;
          text-align: left;
          color: rgba(0, 0, 0, 0.7);
          border: none !important;
          i {
            font-size: 13px;
            margin-right: 5px;
          }
          &:hover {
            color: $blueNew;
          }
        }
      }
    }
  }
}
