$btnHeightType1: 40px;

button {
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;

  &.type1 {
    height: $btnHeightType1;
    line-height: $btnHeightType1;
    padding: 0 30px;
    color: $greyDark;
    background: white;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);

    &:hover {
      // background:$blueDark;
      color: $blueDark;
      border-color: $blueDark;
    }
  }

  &.type2 {}

  &.buttonInList {
    cursor: pointer;
    background: transparent;
    color: $greyDark;
    border: none;
    font-size: 12px;
    padding-left: 0;
    margin-right: 20px;

    &:hover {
      color: $orange;
    }
  }

  &.buttonInListBlue {
    @extend .buttonInList;
    // color:rgba(123,190,250,0.2);
    color: white;
  }

  i {
    margin-right: 5px;
  }

  &.actionButton {
    background: none;
    color: $greyNew;
    border: none;
    font-size: 12px;
    padding: 0px;
    margin-right: 25px;

    &:hover {
      color: $blueNew;
    }
  }

  &.circle {
    border-radius: 50%;
    margin-left: 3px;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background: transparent;
    color: #9e999b;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:hover {
      border-color: #9e999b;
    }
  }

  &.gridButton {
    cursor: pointer;
    background: $blueNew;
    color: white;
    font-size: 14px;
    height: 26px;
    font-weight: 700;

    &:hover {
      color: white;
    }

    &.circle {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      margin: 0;
      padding: 0;

      i {
        margin: 0;
        padding: 0;
      }
    }

    &.disabled {
      background: #a9a9a9;
      border: 1px solid #a9a9a9;

      &:hover {
        background-color: #a9a9a9;
        color: white;
      }
    }

    &.orange {
      background: #e88617;
      border: 1px solid #e88617;

      &:hover {
        background-color: white;
        color: #e88617;
      }
    }

    &.red {
      background: #a40606;
      border: 1px solid #a40606;

      &:hover {
        background-color: white;
        color: #a40606;
      }
    }
  }
}

.mapButton {
  cursor: pointer;
  background: $blueNew;
  color: white;
  font-weight: 700;
  margin: 2px;

  &:hover {
    color: white;
  }

  &.circle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 0;

    i {
      margin: 0;
      padding: 0;
    }
  }

  &.disabled {
    background: #a9a9a9;
    border: 1px solid #a9a9a9;

    &:hover {
      background-color: #a9a9a9;
      color: white;
    }
  }

  &.orange {
    background: #e88617;
    border: 1px solid #e88617;

    &:hover {
      background-color: white;
      color: #e88617;
    }
  }

  &.red {
    background: #a40606;
    border: 1px solid #a40606;

    &:hover {
      background-color: white;
      color: #a40606;
    }
  }

  &.ordered {
    background-color: #F6AA1C;
    border: 1px solid #F6AA1C;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #F6AA1C;
    }
  }

  &.hurry {
    background-color: red;
    border: 1px solid red;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: red;
    }
  }

  &.scheduled {
    background-color: #AEE063;
    border: 1px solid #AEE063;
    margin-right: 10px;

    &:hover {
      background-color: black;
      color: #AEE063;
    }
  }

  &.ongoing {
    background-color: #F7D8C3;
    border: 1px solid #F7D8C3;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #F7D8C3;
    }
  }

  &.done {
    background-color: #8BB8D6;
    border: 1px solid #8BB8D6;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #8BB8D6;
    }
  }


  &.canceled {
    background-color: #d53e3e;
    border: 1px solid #d53e3e;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #d53e3e;
    }
  }

  &.none {
    background-color: #000000;
    border: 1px solid #000000;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #000000;
    }
  }

  &.notReceived {
    background-color: #60686d;
    border: 1px solid #60686d;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: #60686d;
    }
  }

  // Spécialistes
  &.actif {
    background: #19b5fe;
    border: 1px solid #19b5fe;
    margin-right: 10px;

    &:hover {
      color: #19b5fe;
      background-color: white;
    }
  }

  &.confirme {
    background: #446cb3;
    border: 1px solid #446cb3;
    margin-right: 10px;

    &:hover {
      color: #446cb3;
      background-color: white;
    }
  }

  &.non_confirme {
    background: #f2784b;
    border: 1px solid #f2784b;
    margin-right: 10px;

    &:hover {
      color: #f2784b;
      background-color: white;
    }
  }

  &.nouveaux {
    background: #61E786;
    border: 1px solid #61E786;
    margin-right: 10px;

    &:hover {
      color: #61E786;
      background-color: white;
    }
  }

  &.bloques {
    background: #ef4836;
    border: 1px solid #ef4836;
    margin-right: 10px;

    &:hover {
      color: #ef4836;
      background-color: white;
    }
  }

  &.inactifs {
    background: #1d2325;
    border: 1px solid #1d2325;
    margin-right: 10px;

    &:hover {
      color: #1d2325;
      background-color: white;
    }
  }
}
