.mission-header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 10px;
  flex-direction: column;
    .action-bar {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      .item {
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &:first-child {
          i {
            color: #f5a623;
          }
        }
        &:hover {
          cursor: pointer;
          p {
            text-decoration: underline;
          }
        }

        i {
          font-size: 25px;
          margin-right: 7px;
          margin-left: 7px;
          margin-bottom: 7px;
        }
      }
    }
  }

  .hover-icon {
    cursor: pointer;
  }


