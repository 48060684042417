$navbar-height: 50px;
$bluev2: #1f5288;
$break-medium: 800px;

.navbar_v2_item {
  display: flex;
  background: white;
  flex-direction: row;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1000;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      cursor: pointer;
      color: $greyDark;
      height: 70px;
      margin: 1rem 1.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .navbar_contact {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    @extend .lato;
    color: $blueNew;
  }

  .navbar_actions {
    flex: 1;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      @extend .lato;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;
      color: $greyNew;
      text-decoration: none;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 2px;

      .line {
        display: none;
        height: 3px;
        border-radius: 1.5px;
        background-color: $bluev2;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      }

      &.active > .line {
        display: inherit;
      }

      &:hover,
      &.active {
        cursor: pointer;
        color: $bluev2;
      }
    }

    .space {
      flex: 1;
    }

    button {
      &.link {
        @extend .lato;
        cursor: pointer;
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 5px;
        border: none;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        color: $greyNew;
        text-decoration: none;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;

        &:hover,
        &.active {
          cursor: pointer;
          color: $blueNew;
          border-bottom: 2px solid $blueNew;
        }
      }

      &.view-phone {
        margin-right: 20rem;
        border-radius: 25px;
        box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.15%);
        background-color: #1f5288;
        border: solid 1px #1f5288;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .7rem 2rem;

        svg {
          margin-right: 10px;
        }

        @media screen and (max-width: $break-medium) {
          padding-right: 0px;

          span {
            display: none;
          }
        }
      }

      &.edit-infos {
        margin-right: 10px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.15);
        background-color: #f5a623;
        border: solid 1px #f5a623;
        color: white;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 15px;

        svg {
          margin-right: 10px;
        }

        &:hover {
          background: #fdc15c;
          border: solid 1px #fdc15c;
        }

        @media screen and (max-width: $break-medium) {
          padding-right: 0;

          span {
            display: none;
          }
        }
      }

      &.button {
        margin-left: 30px;
        margin-right: 30px;
        background: transparent;
        border: 1px solid $grey2New;
        color: $grey2New;
        font-size: 12px;
        height: 27px;
        line-height: 24px;
        padding: 0 15px;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
          background: $blueNew;
          color: white;
          border-color: $blueNew;
        }
      }
    }

    .navbar_contact {
      display: inline-block;
    }
  }
}

.test {
  margin-top: 2px;
  background: white;
}
