$color : #1f5288;
$break-xmedium: 1500px;

a {
  color: $color;
}

.cardFilter {
  position: absolute;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.97;
  width: 45%;

  .ant-spin-nested-loading {
    height: calc(100% - (100% - 70%));
  }

  @media screen and (max-width: $break-xmedium) {
    width: 65%;

    .ant-spin-nested-loading {
      height: calc(100% - (100% - 47%));
    }
  }
}

#mapContainer {
  z-index: 9999999;
}

.search-control-icon-button {
  padding-top: 4px;
}

.clusterSpan {
  position: relative;
  text-align: center;
  //height: 40px;
  //width: 40px;
  color: white;
}

.clusterCounter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  font-size: 18px;
  font-weight: bolder;
}

.clusterCounterSpe {
  transform: translate(-50%, -80%);
  color: white;
  font-weight: bolder;
  font-size: 18px;
}

.clusterIcon {
  height: 40px;
  width: 40px;
}

.filterDiv {
  margin: 15px 15px 10px 15px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.filterDiv .formInput label {
  text-align: center;
}

.filterDiv .font-title-blue {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.filterDiv .formInput {
  margin: 0 15px;

  .ant-picker-range {
    width: 100%;
  }
}

.filterDiv .ant-row {
  margin-top: 10px;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

.ant-collapse-borderless>.ant-collapse-item {
  border: 0;
}

.ant-collapse-borderless.additionalFilters>.ant-collapse-item .ant-collapse-header {
  position: absolute;
  top: -80px;
  right: 0;
}

.filterIndicator {
  text-align: center;
  font-size: 14px;
}

.filterIndicator span {
  color: $color;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
// Inspections list
.inspectionTileItem, .missionTitleItem {
  font-size: 14px !important;
  margin: 15px;
  padding: 5px;
  background: white;
  box-shadow: 1px 1px 1px #80808042;
  border: 1px solid #80808054 !important;
  border-radius: 3px;

  .inspectionbutton {
    display: flex;
    justify-content: flex-end;

    .firstBlocButton {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #bdc3c7;
    }
  }

  &.card {
    .ant-checkbox-wrapper {
      width: 100%;
      line-height: 0;
    }
  }
}

.ant-checkbox-group {
  display: block;
}

.inspectionTileId {
  color: $color;
  font-weight: 600;
  font-size: 16px;
  padding-left: 15px;
  text-align: center;
  margin-top: 22px;
}




.inspectionTileStatus {
  font-size: 18px;
  padding-left: 15px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  width: 125px;
  line-height: 2.5;

  &.ordered {
    color: #F4AC23;
  }

  &.hurry {
    color: red;
  }

  &.scheduled {
    color: #AEE068;
  }

  &.ongoing {
    color: #a38774;
  }

  &.done {
    color: #8BB8D6;
  }

  &.canceled {
    color: #d44344;
  }
}

.mission.inspectionTileStatus {
  line-height: 3;
  font-size: 1.3rem;
}

.resetFilters {
  color: rgba(0, 0, 0, 0.7);
  margin-top: 15px;
  margin-left: -2px;

  &:hover {
    color: rgba(0, 0, 0, 0.9)
  }
}

.rightMenuCard {
  float: right;
  width: 40%;

  div {
    display: inline-block;
  }

  .inspectionTileContact {
    margin-right: 15%;
  }

  .icon-action-inspection {

    a {
      display: block;
    }
  }
}

.addressCard {
  width: 40vh;
}

.ant-list-sm .ant-list-item {
  padding: 5px;
}

.ant-checkbox-inner {
  border-radius: 0;
}
