.loginPage{
	width:400px;
	margin-left: auto;
	margin-right: auto;
	padding:15px;
	border-radius:4px;
	background:white;
	margin-top:50px;
	@extend .boxshadow;
	text-align:center;
	form{
		text-align:left;
		width:350px;
		margin-top:30px;
		padding:0px 20px;
		margin-left: auto;
		margin-right: auto;
		font-size:12px;
	}
	input[type="email"], input[type="text"], input[type="password"]{
		width: 100%!important;
		max-width: 100%!important;
	}
	input[type="checkbox"]{
		margin-right:6px;
	}
	.helper{
		font-size:13px;
		text-align:center;
	}
	.formButton{
		margin-top:20px;
	}
}
